import React from "react";
import { Stack } from "react-bootstrap";

// components
import ToggleButtonGroup from "../../shared/components/button-group/_ToggleButtonGroup";
import ResourceItem from "../../shared/components/resource-item/_ResourceItem";
import Label from "../../shared/components/label/_Label";

//services
import {
  BRAKE_COMPLIANCE_COMPONENTS,
  BRAKE_COMPLIANCE_CONST,
} from "./_BrakeComplianceConst";
import { Link } from "react-router-dom";

const BrakeComplianceMain = ({
  complianceDetails,
  onUpdate,
  onShowComponent,
}) => {
  const brakeComplianceResults = ["Yes", "No", "N/A"];

  const onSelectBrakeCompliance = (res) => {
    let brakeCompliance = 0;
    switch (res) {
      case "Yes":
        brakeCompliance = 1;
        break;
      case "No":
        brakeCompliance = 0;
        break;
      case "N/A":
        brakeCompliance = 2;
        break;
      default:
        brakeCompliance = 2;
        break;
    }
    onUpdate({
      ...complianceDetails,
      brakeCompliance: brakeCompliance,
      brakeComplianceDisplay: res,
    });
  };

  const onSelectManufacturer = () => {
    onShowComponent(BRAKE_COMPLIANCE_COMPONENTS.MANUFACTURER);
  };

  const onClearManufacturer = () => {
    onUpdate({ ...complianceDetails, machineManufacturer: "N/A" });
  };

  return (
    <Stack gap={3}>
      <Label text={"BRAKE COMPLIANCE"} className={"fw-light"} />
      <ToggleButtonGroup
        name={"brakeCompliance"}
        possibleResults={brakeComplianceResults}
        selectedResult={complianceDetails.brakeComplianceDisplay}
        onSelect={onSelectBrakeCompliance}
      />

      <Link to="#" onClick={onClearManufacturer}>
        Clear Manufacturer
      </Link>
      <ResourceItem
        title={"title"}
        description={BRAKE_COMPLIANCE_CONST.SELECT_MANUFACTURER_TITLE}
        action={complianceDetails.machineManufacturer}
        onClick={onSelectManufacturer}
      />

      {/* <Label text={`Manufacturer: ${complianceDetails.machineManufacturer ?? ""}`} />
            <LargeButton
              title="Update"
              onClick={onUpdateClick}
            /> */}
    </Stack>
  );
};

export default BrakeComplianceMain;
