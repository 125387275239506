import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// components
import InspectorMain from "./components/_InspectorMain";
import AddInspector from "./components/_AddInspector";

//services
import { useUpdateInspectorMutation } from "../../redux/features/inspectionLine/inspectionApiSlice";
import { buildFromMutation } from "../../services/utils/statusUtils";

//hooks
import { useInspectionLine } from "../../hooks/useInspectionLine";
import { useElevatorContacts } from "../../hooks/useElevatorContacts";

const Inspector = () => {
  const navigate = useNavigate();
  const { inspectionLine } = useInspectionLine();
  const { fetch } = useElevatorContacts();
  // update inspector in db
  const [updateInspector] = useUpdateInspectorMutation();
  const [contactDetails, setContactDetails] = useState({
    inspectionLineId: 0,
    elevatorContactId: 0,
    testingCompanyId: 0,

    firstName: "",
    lastName: "",
    license: "",
    status: null,
  });

  const [showAddInspector, setShowAddInspector] = useState(false);

  const [elevatorContacts, setElevatorContacts] = useState({
    contacts: [],
    status: null,
  });

  useEffect(() => {
    if (inspectionLine) {
      const asyncFetch = async () => {
        const { contactDetails, elevatorContacts, status } = await fetch(
          inspectionLine
        );

        setContactDetails(contactDetails);
        setElevatorContacts({ contacts: elevatorContacts, status: status });
      };

      asyncFetch();
    }
  }, [inspectionLine, fetch]);

  const onBackClick = () => {
    // navigate(
    //   INTERNAL_ROUTES.INSPECTION.replace(
    //     INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
    //     inspectionLineId
    //   )
    // );

    navigate(-1);
  };

  const onUpdateInspector = async (opt) => {
    // update mutation into redux
    // we need the id of the elevator contact
    // the description of the elevator contact has the name and license in one

    let payload = {
      inspectionLineId: contactDetails.inspectionLineId,
      elevatorContactId: opt.id,
    };

    const response = await updateInspector(payload);
    const status = buildFromMutation(
      response,
      "Updated inspector!",
      "Failed to update inspector!"
    );

    if (status.hasError) {
      setContactDetails((prev) => ({ ...prev, status: status }));
      return;
    }

    onBackClick();
    return;
  };

  const onAddInspector = async (payload) => {
    setShowAddInspector(true);
  };

  const render = () => {
    if (showAddInspector)
      return (
        <AddInspector inspectionLine={inspectionLine} onBack={onBackClick} />
      );

    return (
      <InspectorMain
        elevatorContacts={elevatorContacts}
        state={contactDetails}
        onUpdate={onUpdateInspector}
        onBack={onBackClick}
        onAdd={onAddInspector}
      />
    );
  };

  return <div>{render()}</div>;
};

export default Inspector;
