import { useInspectionLine } from "./useInspectionLine";
import { buildFromQuery } from "../services/utils/statusUtils";
import { STATUS_MESSAGES } from "../services/constants/inspectionImageConsts";
import { useGetInspectionLineImagesQuery } from "../redux/features/inspectionLine/inspectionApiSlice";
import { useEffect } from "react";

export const useGetImages = () => {
  const {
    inspectionLine,
    isLoading: insLoading,
    status: insStatus,
    isSuccess: insSuccess,
    lockedStatus,
  } = useInspectionLine();

  const imagesApi = useGetInspectionLineImagesQuery(
    {
      inspectionLineId: inspectionLine?.inspectionLineId,
      inspectionId: inspectionLine?.inspectionId,
    },
    {
      skip: !inspectionLine,
    }
  );

  const { data, isSuccess, isLoading, isFetching } = imagesApi;
  const status = buildFromQuery(imagesApi, null, STATUS_MESSAGES.FAILED_QUERY);

  const refetch = () => {
    console.log(imagesApi);
    if (!imagesApi.isUninitialized) imagesApi.refetch();
  };

  const images = data?.results;
  console.log("get images data", data);

  return {
    inspectionLine,
    lockedStatus,
    images: images,
    isLoading: insLoading || isLoading || isFetching,
    isSuccess: insSuccess && isSuccess,
    status: !insSuccess ? insStatus : status,
    refetch,
  };
};
