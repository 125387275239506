import React from "react";
import { useSelector } from "react-redux";

// services
import { VERSION } from "../../services/constants/appConsts";
import { Image, Stack } from "react-bootstrap";

const Home = () => {
  const profile = useSelector((state) => state.profile);

  return (
    <Stack
      direction="vertical"
      className="justify-content-evenly tab-layout-content-height"
    >
      <Stack
        direction="vertical"
        className="justify-content-center align-items-center"
        gap={5}
      >
        <Image
          title="VDA logo"
          src="vda-logo.svg"
          style={{ height: "10rem", width: "10rem" }}
        />
        <Stack direction="horizontal" className="justify-content-center">
          <h1>
            Welcome{" "}
            <span style={{ color: "#74002D", fontSize: "inherit" }}>
              {profile.firstName}
            </span>
            ,
          </h1>
        </Stack>
        <div>
          <h3>You have some work to do today!</h3>
          <h3>We believe in you!</h3>
        </div>
      </Stack>
      <h3>v: {VERSION}</h3>
    </Stack>
  );
};

export default Home;
