export const isNullOrWhitespace = (value) => {
  if (value === null) return true;
  if (value.length === 0) return true;
  if (value === "") return true;

  for (const c in value) {
    if (c !== " ") return false;
  }

  return true;
};
