import React from "react";
import { Stack } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ConfirmModal({
  show,
  title,
  text,
  onConfirm,
  confirmBtnText,
  onClose,
  closeBtnText,
}) {
  const renderConfirm = () => {
    if (!onConfirm) return;

    return (
      <Button size="lg" variant="primary" onClick={onConfirm}>
        {confirmBtnText ?? "Confirm"}
      </Button>
    );
  };
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>{title}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{text}</h4>
      </Modal.Body>
      <Modal.Footer>
        <Stack direction="horizontal" gap={5}>
          <Button size="lg" variant="secondary" onClick={onClose}>
            {closeBtnText ?? "Close"}
          </Button>
          {renderConfirm()}
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
