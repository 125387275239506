import React, { useState, useEffect } from "react";

// components
import InspectionImagesMain from "./components/_InspectionImageMain";
import ResourceListPage from "../shared/components/resource-list/_ResourceListPage";
import { Col, Container, Row } from "react-bootstrap";

//services
import { useNavigate } from "react-router-dom";
import {
  useAddInspectionLineImageMutation,
  useClearInspectionLineImageMutation,
} from "../../redux/features/inspectionLine/inspectionApiSlice";
import {
  buildEmptyInspectionImageItem,
  buildImageItemsArray,
} from "../../services/utils/inspectionImage";
import { STATUS_MESSAGES } from "../../services/constants/inspectionImageConsts";
import { useResources } from "../../hooks/useResources";
import { buildFromMutation } from "../../services/utils/statusUtils";
import PageError from "../shared/components/error/_PageError";
import PageSpinner from "../shared/components/spinner/_PageSpinner";
import Header from "../shared/components/header/_Header";
import StatusMessage from "../shared/components/status-message/_StatusMessage";
import CompletedStatus from "../shared/components/status-message/_CompletedStatus";
import ConfirmModal from "../shared/components/modal/_ConfirmModal";
import { INSP_IMAGE_MESSAGES } from "./_InspectionImagesConts";
import { useGetImages } from "../../hooks/useGetImages";

const InspectionImages = () => {
  const navigate = useNavigate();
  const [showPartsResourceForPartItem, setShowPartsResourceForPartItem] =
    useState(null);
  const [showConfirmPendingAbandon, setShowConfirmPendingAbandon] =
    useState(false);
  const { inspectionLine, lockedStatus, images, isLoading, status, refetch } =
    useGetImages();


  //console.log("images images", images);

  const [state, setState] = useState({
    pending: [],
    items: [],
    mutationStatus: null,
  });

  const [addImage] = useAddInspectionLineImageMutation();
  const [clearImage] = useClearInspectionLineImageMutation();
  const { elevatorParts } = useResources();
  const items = isLoading ? [] : buildImageItemsArray(images, elevatorParts);

  //console.log("images items", items);

  const navigateBack = () => {
    navigate(-1);
  };

  const onBackClick = () => {
    if (state.pending?.length > 0) {
      setShowConfirmPendingAbandon(true);
      return;
    }

    navigateBack();
  };

  const onSelectImage = (imageItem, imageFile) => {
    if (lockedStatus.isLocked) return;
    updatePending({ ...imageItem, image: imageFile, pending: true });
    uploadImage(imageItem);
  };

  const onClearPartItem = (imageItem) => {
    if (lockedStatus.isLocked) return;
    clearImageItem(imageItem);
  };

  const onSelectPart = (imageItem, selectedPart) => {
    if (lockedStatus.isLocked) return;
    updatePending({ ...imageItem, part: selectedPart, pending: true });
    setShowPartsResourceForPartItem(null);
    uploadImage(imageItem);
  };

  const updatePending = (pendingItem) => {
    const indexOfPending = state.pending.findIndex(
      (p) => p.imageIndex === pendingItem.imageIndex
    );

    if (indexOfPending > -1) {
      state.pending[indexOfPending] = pendingItem;
    } else {
      state.pending.push(pendingItem);
    }
    setState((prev) => ({ ...prev, pending: [...state.pending] }));
  };

  const clearImageItem = async (imageItem) => {
    if (!inspectionLine) {
      return;
    }

    //Only clear if there is a URI present
    if (imageItem?.imageUri) {
      const line = inspectionLine;

      const resp = await clearImage({
        inspectionId: line.inspectionId,
        inspectionLineId: line.inspectionLineId,
        imageIndex: imageItem.imageIndex,
      });

      const status = buildFromMutation(
        resp,
        STATUS_MESSAGES.SUCCESS_CLEARING,
        STATUS_MESSAGES.FAILED_CLEARING
      );

      setState((prev) => ({
        ...prev,
        mutationStatus: status,
      }));
    }

    clearImageInState(imageItem);
  };

  const clearImageInState = (imageItem) => {
    const empty = buildEmptyInspectionImageItem(imageItem.imageIndex);
    state.items[imageItem.imageIndex] = empty;
    setState((prev) => ({ ...prev }));
  };

  const uploadImage = async (imageItem) => {
    if (!inspectionLine) {
      return;
    }

    const { image, part } = state.pending.find(
      (p) => p.imageIndex === imageItem.imageIndex
    );

    if (!image || !part) {
      setState((prev) => ({
        ...prev,
        mutationStatus: null,
      }));
      return;
    }

    const formData = new FormData();
    formData.append("file", image);

    const resp = await addImage({
      inspectionId: inspectionLine.inspectionId,
      inspectionLineId: inspectionLine.inspectionLineId,
      partId: part.id,
      imageIndex: imageItem.imageIndex,
      formData: formData,
    });

    const status = buildFromMutation(
      resp,
      STATUS_MESSAGES.SUCCESS_MUTATION,
      STATUS_MESSAGES.FAILED_MUTATION
    );

    if (status.hasError) {
      setState((prev) => ({
        ...prev,
        mutationStatus: status,
      }));
      return;
    }

    setState((prev) => ({
      ...prev,
      pending: prev.pending.filter(
        (p) => p.imageIndex !== imageItem.imageIndex
      ),
      mutationStatus: status,
    }));
  };

  const renderMainContent = () => {
    if (isLoading) return <PageSpinner show={true} />;

    if (!inspectionLine)
      return (
        <PageError
          show={true}
          subTitle={"Failed to fetch inspection line, try to refresh."}
        />
      );

    if (status.hasError)
      return <PageError show={true} status={status} refresh={refetch} />;

    return (
      <InspectionImagesMain
        items={items}
        pending={state.pending}
        onSelectImage={onSelectImage}
        onClearPartItem={onClearPartItem}
        onSelectPart={(partItem) => setShowPartsResourceForPartItem(partItem)}
        isLocked={lockedStatus.isLocked}
      />
    );
  };

  if (showPartsResourceForPartItem)
    return (
      <ResourceListPage
        title={"Elevator Part"}
        subTitle={"Select a part"}
        resources={elevatorParts}
        onSelect={(part) => onSelectPart(showPartsResourceForPartItem, part)}
        onBack={() => setShowPartsResourceForPartItem(null)}
      />
    );

  return (
    <Container>
      <Row>
        <Col>
          <Header
            title={"Inspection Photos"}
            subTitle={"You can upload a maximum of 6 images"}
            onBack={onBackClick}
            refresh={refetch}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ConfirmModal
            title={"Confirm"}
            text={INSP_IMAGE_MESSAGES.PENDING_DISCLAIMER}
            show={showConfirmPendingAbandon}
            onClose={() => setShowConfirmPendingAbandon(false)}
            onConfirm={() => navigateBack()}
          />
          <StatusMessage status={state.queryStatus} />
          <StatusMessage status={state.mutationStatus} />
          <CompletedStatus lockedStatus={lockedStatus} />
          {renderMainContent()}
        </Col>
      </Row>
    </Container>
  );
};

export default InspectionImages;
