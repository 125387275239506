export const INSPECTION_LABELS = {
  ERROR_MODAL_CANT_COMPLETE: "Can't complete",

  HEADER_TITLE: "Inspection Line",

  INSPECTION_DEVICE: "Device",
  INSPECTION_STATUS: "Status",
  INSPECTION_ID: "Insp. Id",
  INSPECTION_DATE: "Insp. Date",

  INSPECTION_BRAKE_COMPLIANCE: "BRAKE COMPLIANCE - MACHINE MANUFACTURER",
  INSPECTION_BRAKE_COMPLIANCE_DESCRIPTION: (
    brakeComplianceDisplay,
    machineManufacturer
  ) =>
    (brakeComplianceDisplay !== null
      ? brakeComplianceDisplay
      : "Select Status") +
    " - " +
    (machineManufacturer !== null
      ? machineManufacturer
      : "Select Manufacturer"),

  INSPECTION_COMMENTS: "INSPECTION DEVICE COMMENTS",
  INSPECTION_COMMENTS_DESCRIPTION: "View Comments",

  INSPECTION_INSPECTOR: "ELEVATOR INSPECTOR",
  INSPECTION_INSPECTOR_DESCRIPTION: (fullName, license) =>
    fullName ? fullName + " - " + license : "Select Inspector",

  INSPECTION_DEF: (nbOfDeficiencies) =>
    `DEFICIENCIES (${nbOfDeficiencies ?? "N/A"})`,
  INSPECTION_DEF_DESCRIPTION: "View Device Deficiencies",

  INSPECTION_DEF_HISTORY: "DEFICIENCY HISTORY",
  INSPECTION_DEF_HISTORY_DESCRIPTION: "Previous Inspection Results",

  INSPECTION_IMAGES: "INSPECTION PHOTOS",
  INSPECTION_IMAGES_DESCRIPTION: "View/Take Photos",

  COMPLETE_BTN_LOCKED: "Line completed, cannot update.",
};
