import React from "react";
import { Badge } from "react-bootstrap";
import Label from "../label/_Label";
import { getMobileStatusLabel, getStatusBadgeColor } from "../../../../services/utils/inspectionType";

const StatusBadge = ({ isCompleted, mobileStatus }) => {
  return (
    <Badge
      className={`${getStatusBadgeColor(isCompleted, mobileStatus)} m-0 p-0`}
    >
      <Label
        className="m-1 text-center"
        text={`${getMobileStatusLabel(isCompleted, mobileStatus)}`}
      />
    </Badge>
  );
};

export default StatusBadge;
