import { createSlice } from "@reduxjs/toolkit";
import { usersApi } from "../users/usersApiSlice";
import { getToday } from "../../../services/utils/dateUtils";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  isAdmin: false,
  user: null,
  selectedDay: getToday(),
};

export const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    setProfile: (state) => {
      state.firstName = "Mike";
    },
    clearProfile: (state) => {
      state.firstName = "Reset";
    },
    setSelectedDay: (state, action) => {
      state.selectedDay = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        usersApi.endpoints.getCurrentUser.matchFulfilled,
        (state, { payload }) => {
          //console.log('profile', payload);
          const result = payload?.result;
          if (result) {
            state.user = result;
            state.firstName = result.firstName;
            state.lastName = result.lastName;
            state.email = result.email;
            state.isAdmin = result.isAdmin;
          } else {
            state.user = null;
            state.firstName = null;
            state.lastName = null;
            state.email = null;
            state.isAdmin = false;
          }
        }
      )
      .addMatcher(
        ({ error, payload, type }) => {
          //console.log("payload", payload);
          //console.log("error", error);
          if (payload?.status === 401) return true;
          return false;
        },
        (state, other) => {
          //console.log("other", other);
          //console.log("state", state);
          //TODO: figure out handle if any of the request return 401, we have to somehow invalidate the GetCurrentUser cache
          //set something in localstorage maybe?
        }
      );
  },
});

export const { setProfile, clearProfile, setSelectedDay } =
  profileSlice.actions;

export default profileSlice.reducer;
