import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  INSPECTION_ROUTES_PARAMS,
  INTERNAL_ROUTES,
} from "../../routing/nav_routes";

// components
import { Col, Container, Row, Stack } from "react-bootstrap";
import Header from "../shared/components/header/_Header";

//services
import DeficiencyLine from "./components/_DeficiencyLine";
import { useInspectionLine } from "../../hooks/useInspectionLine";
import CompletedStatus from "../shared/components/status-message/_CompletedStatus";
import { useDeficiencies } from "../../hooks/useDeficiencies";
import { useDeficiencyMutations } from "../../hooks/useDeficiencyMutations";
import PageSpinner from "../shared/components/spinner/_PageSpinner";
import PageError from "../shared/components/error/_PageError";
import StatusMessage from "../shared/components/status-message/_StatusMessage";

//css

const InspectionDeficiencies = () => {
  const navigate = useNavigate();
  const { inspectionLineId } = useParams();

  const {
    deficiencies,
    isLoading: defIsLoading,
    isSuccess: defIsSuccess,
    status: defStatus,
  } = useDeficiencies();
  const { deleteDeficiency } = useDeficiencyMutations();
  const [deleteStatus, setDeleteStatus] = useState(null);
  const {
    isLoading: insLoading,
    status: insStatus,
    isSuccess: insIsSuccess,
    lockedStatus,
  } = useInspectionLine();
  

  const isLoading = defIsLoading || insLoading;  

  const onBackClick = () => {
    // navigate(
    //   INTERNAL_ROUTES.INSPECTION.replace(
    //     INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
    //     inspectionLineId
    //   )
    // );

    navigate(-1);
  };

  const onAddClick = () => {
    if (lockedStatus.isLineCompleted) return;

    // we are adding a new deficiency - pass 0 as the id
    navigate(
      INTERNAL_ROUTES.INSPECTION_DEFICIENCIES_DETAILS.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      ).replace(INSPECTION_ROUTES_PARAMS.DEFICIENCY_ID, 0)
    );
  };

  const onOpenDeficiency = (def) => {
    if (lockedStatus.isLineCompleted) return;

    navigate(
      INTERNAL_ROUTES.INSPECTION_DEFICIENCIES_DETAILS.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      ).replace(INSPECTION_ROUTES_PARAMS.DEFICIENCY_ID, def.deficiencyId)
    );
  };

  const onDeleteDeficiency = async (def) => {
    // TODO add loader
    if (lockedStatus.isLineCompleted) return;
    const status = await deleteDeficiency(def.deficiencyId);

    setDeleteStatus(status);
  };

  const renderDeficiencyLines = () => {
    if (!deficiencies || deficiencies.length === 0)
      return <h3 className="mt-5">There are no deficiencies.</h3>;

    return deficiencies.map((d, i) => (
      <DeficiencyLine
        key={i}
        deficiency={d}
        onOpen={onOpenDeficiency}
        onDelete={onDeleteDeficiency}
        disabled={lockedStatus.isLocked}
      />
    ));
  };
  
  const renderMainContent = () => {
    if (!insIsSuccess) return <PageError status={insStatus} />;
    if (!defIsSuccess) return <PageError status={defStatus} />;
    if (isLoading) return <PageSpinner show={true} />;

    return (
      <>
        <CompletedStatus show={lockedStatus} />
        <StatusMessage status={deleteStatus} />
        <Stack gap={2}>{renderDeficiencyLines()}</Stack>
      </>
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <Header
            title={"Device Deficiencies"}
            subTitle={"Insert or edit device deficiencies"}
            onBack={onBackClick}
            onAction={lockedStatus.isLocked ? undefined : onAddClick}
            actionClassName={lockedStatus.isLocked ? undefined : "ri-add-fill"}
          />
        </Col>
      </Row>
      <Row>
        <Col></Col>
      </Row>
      <Row>
        <Col>{renderMainContent()}</Col>
      </Row>
    </Container>
  );
};

export default InspectionDeficiencies;
