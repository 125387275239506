import React from "react";

// components
import { Stack } from "react-bootstrap";
import InspectionImageLine from "./_InspectionImageLine";

const InspectionImagesMain = ({
  items,
  pending,
  onSelectImage,
  onSelectPart,
  onClearPartItem,
  isLocked,
}) => {
  //console.log("InspectionImagesMain", items, pending);

  const buildItems = () => {
    return items?.map((partItem, i) => {
      const pendingItem = pending.find(
        (i) => i.imageIndex === partItem.imageIndex
      );
      return (
        <InspectionImageLine
          key={i}
          partItem={pendingItem ?? partItem}
          onSelectImage={onSelectImage}
          onSelectPart={onSelectPart}
          onClearPartItem={onClearPartItem}
          disabled={isLocked}
        />
      );
    });
  };

  return (
    <Stack gap={2} className="mt-2 mb-2">
      {buildItems()}
    </Stack>
  );
};

export default InspectionImagesMain;
