import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// components
import BrakeComplianceMain from "./components/_BrakeComplianceMain";

//services
import {
  BRAKE_COMPLIANCE_COMPONENTS,
  BRAKE_COMPLIANCE_CONST,
} from "./components/_BrakeComplianceConst";

// redux
import { useUpdateBrakeComplianceMutation } from "../../redux/features/inspectionLine/inspectionApiSlice";
import ResourceListPage from "../shared/components/resource-list/_ResourceListPage";
import { useInspectionLine } from "../../hooks/useInspectionLine";
import { useResources } from "../../hooks/useResources";
import PageError from "../shared/components/error/_PageError";
import PageSpinner from "../shared/components/spinner/_PageSpinner";
import { buildFromMutation, danger } from "../../services/utils/statusUtils";
import { RESOURCE_ERRORS } from "../../services/constants/resourcesMessages";
import { INSPECTION_ERRORS } from "../../services/constants/inspectionMessages";
import StatusMessage from "../shared/components/status-message/_StatusMessage";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../shared/components/header/_Header";

const BrakeCompliance = () => {
  const navigate = useNavigate();

  // REDUX ------------------------------------------------------------
  // on page reload - the inspection lines
  const [updateBrakeCompliance] = useUpdateBrakeComplianceMutation();
  const {
    inspectionLine,
    isSuccess: insSuccess,
    isLoading: insLoading,
    status: insStatus,
  } = useInspectionLine();
  //get manufacturers from resource rtkq
  const {
    manufacturers,
    isSuccess: resSuccess,
    isLoading: resLoading,
    status: resStatus,
  } = useResources();
  // REDUX ------------------------------------------------------------

  const isLoading = insLoading || resLoading;
  const isSuccess = insSuccess && resSuccess;
  const status = insStatus.hasError ? insStatus : resStatus;

  const [state, setState] = useState({
    brakeCompliance: "N/A",
    manufacturer: { id: 1, name: "N/A" },
    updateStatus: null,
  });

  const [complianceDetails, setComplianceDetails] = useState({
    deviceId: 0,
    brakeCompliance: "",
    brakeComplianceDisplay: "",
    machineManufacturer: "",
  });

  useEffect(() => {
    if (inspectionLine) {
      setComplianceDetails({
        deviceId: inspectionLine.deviceId,
        brakeCompliance: inspectionLine.brakeCompliance,
        brakeComplianceDisplay: inspectionLine.brakeComplianceDisplay,
        machineManufacturer: inspectionLine.machineManufacturer,
      });
    }
  }, [inspectionLine]);

  const onBackClick = () => {
    // navigate(
    //   INTERNAL_ROUTES.INSPECTION.replace(
    //     INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
    //     inspectionLineId
    //   )
    // );

    navigate(-1);
  };

  const backToMainComponent = () => {
    setState((prevState) => ({
      ...prevState,
      updateStatus: null,
      componentToShow: BRAKE_COMPLIANCE_COMPONENTS.MAIN,
    }));
  };

  const onShowComponent = (comp) => {
    // show main component or the manufactuerers list component
    setState((prevState) => ({
      ...prevState,
      updateStatus: null,
      componentToShow: comp,
    }));
  };

  const onSelectManufacturer = (opt) => {
    setState((prevState) => ({
      ...prevState,
      updating: true,
      componentToShow: BRAKE_COMPLIANCE_COMPONENTS.MAIN,
    }));

    // selection from the list of manufacturers
    let payload = {
      deviceId: complianceDetails.deviceId,
      brakeCompliance: complianceDetails.brakeCompliance,
      brakeComplianceDisplay: complianceDetails.brakeComplianceDisplay,
      machineManufacturer: opt.description,
    };

    updateComplianceDetailsToDb(payload);

    setState((prevState) => ({
      ...prevState,
      componentToShow: BRAKE_COMPLIANCE_COMPONENTS.MAIN,
    }));
  };

  const onBrakeComplianceChange = (compDetails) => {
    setState((prevState) => ({
      ...prevState,
      updateStatus: null,
      updating: true,
    }));

    // update from button group

    // user has selected a brake compliance status change
    let payload = {
      deviceId: compDetails.deviceId,
      brakeCompliance: compDetails.brakeCompliance,
      brakeComplianceDisplay: compDetails.brakeComplianceDisplay,
      machineManufacturer: compDetails.machineManufacturer,
    };

    updateComplianceDetailsToDb(payload);
  };

  const updateComplianceDetailsToDb = async (payload) => {
    // hit redux api to update the database

    let response = await updateBrakeCompliance(payload);
    // handle error
    const updateStatus = buildFromMutation(
      response,
      BRAKE_COMPLIANCE_CONST.UPDATE_SUCCESS,
      BRAKE_COMPLIANCE_CONST.UPDATE_FAILED
    );
    setState((prev) => ({
      ...prev,
      updating: false,
      updateStatus: updateStatus,
    }));

    if (updateStatus.hasError) {
      return;
    }

    setComplianceDetails(payload);
  };

  const renderMainContent = () => {
    if (isLoading || state.updating) return <PageSpinner show={true} />;
    if (!isSuccess) return <PageError status={status} show={true} />;
    if (!inspectionLine)
      return (
        <PageError
          status={danger(INSPECTION_ERRORS.FAILED_TO_LOAD_LINE)}
          show={true}
        />
      );
    if (!manufacturers)
      return (
        <PageError
          status={danger(RESOURCE_ERRORS.FAILED_TO_LOAD_MANUFACTURERS)}
          show={true}
        />
      );

    return (
      <>
        <StatusMessage
          status={state.updateStatus}
          show={state.updateStatus !== null}
        />
        <BrakeComplianceMain
          complianceDetails={complianceDetails}
          onUpdate={onBrakeComplianceChange}
          onShowComponent={onShowComponent}
        />
      </>
    );
  };

  const render = () => {
    // render components
    switch (state.componentToShow) {
      case BRAKE_COMPLIANCE_COMPONENTS.MANUFACTURER:
        // show the resource list
        return (
          <ResourceListPage
            title={BRAKE_COMPLIANCE_CONST.SELECT_MANUFACTURER_DESCRIPTION}
            resources={manufacturers}
            onSelect={onSelectManufacturer}
            onBack={backToMainComponent}
          />
        );

      default:
        // we show the main component with read only

        return (
          <Container>
            <Row>
              <Col>
                <Header
                  title={"Brake Compliance / Machine Manufacturer"}
                  subTitle={
                    "Enter Brake Compliance / Machine Manufacturer information here"
                  }
                  onBack={onBackClick}
                />
              </Col>
            </Row>
            <Row>
              <Col>{renderMainContent()}</Col>
            </Row>
          </Container>
        );
    }
  };

  return <div>{render()}</div>;
};

export default BrakeCompliance;
