import React, { useEffect, useState } from "react";
import { Image, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// components
import StatusMessage from "../../internal/shared/components/status-message/_StatusMessage";
import LargeButton from "../../internal/shared/components/buttons/_LargeButton";

// services
import { INTERNAL_ROUTES } from "../../routing/nav_routes";
import { useGetCurrentUserQuery } from "../../redux/features/users/usersApiSlice";
import { navigateToLogin } from "../../services/api/authApi";
import { VERSION } from "../../services/constants/appConsts";
import { useApiPing } from "../../hooks/useApiPing";
import IconButton from "../../internal/shared/components/buttons/_IconButton";

const Login = () => {
  const navigate = useNavigate();
  const user = useGetCurrentUserQuery();
  const { refetch, isLoading, isSuccess, status } = useApiPing();
  const [navigateAfterPing, setNavigateAfterPing] = useState(false);

  //console.log("ping", isLoading, isSuccess, status);

  useEffect(() => {
    if (user.isSuccess) {
      navigate(INTERNAL_ROUTES.HOME, { replace: true });
    }
  }, [user, navigate]);

  useEffect(() => {
    if (isSuccess && navigateAfterPing) {
      navigateToLogin();
    }
  }, [navigateAfterPing, isSuccess, navigate]);

  const onLogin = () => {
    refetch();
    setNavigateAfterPing(true);
  };

  const render = () => {
    return (
      <>
        <Image
          title="VDA logo"
          src="vda-logo.svg"
          style={{ height: "10rem", width: "10rem" }}
        />
        <h2>Welcome To</h2>
        <h2>VDA Mobile</h2>
        <Stack
          direction="horizontal"
          className="justify-content-center align-items-center"
        >
          <h3>v: {VERSION}</h3>
          <IconButton
            className={"p-1"}
            iconClass={"ri-refresh-line"}
            size={"md"}
            onClick={() => window.location.reload()}
          />
        </Stack>

        <StatusMessage status={status} />
        <div className="d-flex justify-content-center">
          <LargeButton
            title={"Log-In"}
            loading={isLoading}
            disabled={isLoading}
            variant="success"
            className="fs-3"
            onClick={onLogin}
          />
        </div>
      </>
    );
  };

  return (
    <div className="app-login">
      <Stack
        direction="vertical"
        gap={2}
        className="justify-content-center align-items-center"
        style={{ height: "90vh" }}
      >
        {render()}
      </Stack>
    </div>
  );
};

export default Login;
