import { useGetCurrentUserQuery } from "../redux/features/users/usersApiSlice";
import { USERS_API_MESSAGES } from "../services/constants/usersMessages";
import { buildFromQuery } from "../services/utils/statusUtils";

export const useGetCurrentUser = () => {
  const userApi = useGetCurrentUserQuery();

  const failMsg = USERS_API_MESSAGES.FAILED_TO_LOAD_CURRENT_USER;
  const status = buildFromQuery(userApi, undefined, failMsg);

  //console.log("useGetCurrentUser", status, userApi);

  return {
    user: userApi.data?.result,
    isLoading: userApi.isLoading || userApi.isFetching,
    isSuccess: !status.hasError,
    status,
  };
};
