import { useGetUsersQuery } from "../redux/features/users/usersApiSlice";
import { USERS_API_MESSAGES } from "../services/constants/usersMessages";
import { buildFromQuery } from "../services/utils/statusUtils";
import { useGetCurrentUser } from "./useGetCurrentUser";

export const useGetUsers = () => {
  const {
    user,
    isLoading: userLoading,
    isSuccess: userIsSuccess,
    status: userStatus,
  } = useGetCurrentUser();

  //console.log(user);
  const usersApi = useGetUsersQuery(
    {},
    {
      skip: !user || userLoading || !userIsSuccess || !user.isAdmin,
    }
  );
  const failMsg = USERS_API_MESSAGES.FAILED_TO_LOAD_USERS;
  const status = buildFromQuery(usersApi, undefined, failMsg);
  const loading = usersApi.isLoading || usersApi.isFetching || userLoading;

  console.log("useGetUsers", usersApi);

  return {
    currentUser: user,
    users: usersApi.data?.results,
    isLoading: loading,
    isSuccess: !status.hasError && userIsSuccess,
    status: userStatus.hasError ? userStatus : status,
  };
};
