import React, { useEffect } from "react";
import { store } from "./redux/store.js";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration.js";
import * as routes from "./routing/nav_routes_builder.js";

const router = createBrowserRouter(routes.build());

function App() {
  //   const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  //   // decides when to show the toast
  //   useEffect(() => {
  //     if (showReload && waitingWorker) {
  //       showToast({
  //         description: (
  //           <div>
  //             A new version of this page is available
  //             <button onClick={() => reloadPage()}>REFRESH</button>
  //           </div>
  //         ),
  //       });
  //     } else closeToast();
  //   }, [waitingWorker, showReload, reloadPage]);

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
