import { useSetUserIdMutation } from "../redux/features/users/usersApiSlice";
import { USERS_API_MESSAGES } from "../services/constants/usersMessages";
import { buildFromMutation } from "../services/utils/statusUtils";

export const useSetUserId = () => {
  const [setUserId] = useSetUserIdMutation();

  const update = async (userId) => {
    const payload = {
      id: userId,
    };

    const response = await setUserId(payload);
    return buildFromMutation(
      response,
      USERS_API_MESSAGES.SUCCESSFULLY_UPDATED_USER_ID,
      USERS_API_MESSAGES.FAILED_TO_UPDATE_USER_ID
    );
  };

  return {
    update,
  };
};
