import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

// components
import ResourceListPage from "../shared/components/resource-list/_ResourceListPage";
import StatusMessage from "../shared/components/status-message/_StatusMessage";

//services
import { USERS_LABELS } from "../../services/constants/usersMessages";
import { mapUsersToResources } from "../../services/utils/userUtils";

//hooks
import { useGetUsers } from "../../hooks/useGetUsers";
import { useSetUserId } from "../../hooks/useSetUserId";
import { Stack } from "react-bootstrap";
import LargeButton from "../shared/components/buttons/_LargeButton";

const UserSwitch = () => {
  const navigate = useNavigate();
  const { update } = useSetUserId();

  const [updateStatus, setUpdateStatus] = useState(null);
  const { currentUser, users, isLoading, status } = useGetUsers();
  const mappedUsers = useMemo(() => mapUsersToResources(users), [users]);
  const revertToMeDisabled = !currentUser || !currentUser.idOverride;

  const onBackClick = () => {
    navigate(-1);
  };

  const onSelect = async (userItem) => {
    // update mutation with redux
    if (userItem) updateUser(userItem.id);
  };

  const onSelectMe = () => {
    if (currentUser) updateUser(currentUser.id);
  };

  const updateUser = async (userId) => {
    const status = await update(userId);

    if (!status.hasError) {
      onBackClick();
      return;
    }

    setUpdateStatus(status);
  };

  const isSelected = (userItem) => {
    if (!currentUser) return false;
    if (currentUser.idOverride) return userItem.id === currentUser.idOverride;
    return userItem.id === currentUser.id;
  };

  const renderBefore = () => {
    return (
      <Stack direction="vertical" gap={1}>
        <StatusMessage status={updateStatus} show={updateStatus !== null} />
        <LargeButton
          disabled={revertToMeDisabled}
          size={"md"}
          title={USERS_LABELS.SELECT_ME}
          onClick={onSelectMe}
        />
      </Stack>
    );
  };

  const render = () => {
    return (
      <>
        <ResourceListPage
          isSelected={isSelected}
          onSelect={onSelect}
          onBack={onBackClick}
          title={USERS_LABELS.SWITCH_TITLE}
          subTitle={USERS_LABELS.SWITCH_SUB_TITLE}
          resources={mappedUsers}
          isLoading={isLoading}
          before={renderBefore()}
          error={status.hasError ? status : null}
        />
      </>
    );
  };

  return <div>{render()}</div>;
};

export default UserSwitch;
