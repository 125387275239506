import { buildFromQuery } from "../services/utils/statusUtils";
import { useGetResourcesQuery } from "../redux/features/resources/resourceApiSlice";
import {
  mapManufacturers,
  mapPartsArray,
  mapRemediesArray,
  mapViolationsArray,
} from "../services/utils/utilities";

export const useResources = () => {
  // get resources
  const resourcesApi = useGetResourcesQuery();

  const { elevatorParts, violations, remedies, manufacturers } =
    resourcesApi.isSuccess
      ? {
          elevatorParts: mapPartsArray(resourcesApi.data.result?.elevatorParts),
          violations: mapViolationsArray(resourcesApi.data.result?.violations),
          remedies: mapRemediesArray(resourcesApi.data.result?.remedies),
          manufacturers: mapManufacturers(
            resourcesApi.data.result?.manufacturers
          ),
        }
      : { elevatorParts: [], violations: [], remedies: [], manufacturers: [] };

  const failMsg = "Failed to fetch resources, try again.";
  const status = buildFromQuery(resourcesApi, undefined, failMsg);

  //console.log("use resources", resourcesApi, elevatorParts);

  return {
    elevatorParts,
    violations,
    remedies,
    manufacturers,
    isLoading: resourcesApi.isLoading || resourcesApi.isFetching,
    isSuccess: !status.hasError,
    status,
  };
};
