import { useGetPingQuery } from "../redux/features/ping/ping";
import { API_MESSAGES } from "../services/constants/commonMessages";
import { buildFromQuery } from "../services/utils/statusUtils";

export const useApiPing = () => {
  const ping = useGetPingQuery();

  const failMsg = API_MESSAGES.API_NOT_RESPONDING;
  const okMsg = API_MESSAGES.API_IS_HEALTHY;
  const status = buildFromQuery(ping, okMsg, failMsg);

  const refetch = () => {
    ping.refetch();
  };

  //console.log("status", status, ping);

  return {
    refetch,
    isLoading: ping.isLoading || ping.isFetching,
    isSuccess: !status.hasError,
    status,
  };
};
