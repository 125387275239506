import React from "react";
import { Stack } from "react-bootstrap";

const CompletedStatus = ({ lockedStatus }) => {
  //console.log('CompletedStatus', lockedStatus);
  if (!lockedStatus) return <></>;

  const { isLocked, isLineCompleted } = lockedStatus;

  if (!isLocked) return;

  if (isLineCompleted) {
    return (
      <div className={"fade text-warning alert alert-warning show m-0"}>
        <Stack direction="horizontal" gap={2} className="align-items-center">
          <i className="ri-lock-2-line" />
          <h4>
            {"This inspection has already been completed and cannot be changed"}
          </h4>
        </Stack>
        <h5>{"For questions, please call the office"}</h5>
      </div>
    );
  }

  return (
    <div className={"fade text-warning alert alert-warning show m-0"}>
      <Stack direction="horizontal" gap={2} className="align-items-center">
        <i className="ri-lock-2-line display-6" />
        <h4>{"This inspection has been completed"}</h4>
      </Stack>
      <h5>{"To make changes, revert to To DO"}</h5>
    </div>
  );
};

export default CompletedStatus;
