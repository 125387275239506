import React from "react";

const StatusMessage = ({ status }) => {
  //console.log("statusmessage", status);
  if (!status) status = { show: false };
  const { message, subText, type, show, statusCode } = status;

  const buildClassName = () => {
    switch (type) {
      case "danger":
        return "fade text-danger alert alert-danger show m-0";
      case "success":
        return "fade text-success alert alert-success show m-0";
      default:
        return "fade alert show m-0";
    }
  };

  if (!show) return <></>;

  const statusCodeMsg = statusCode ? (
    <h6>{`Http status: ${statusCode}`}</h6>
  ) : (
    <></>
  );
  return (
    <div className={buildClassName()}>
      <h4>{message}</h4>
      <h6>{subText}</h6>
      {statusCodeMsg}
    </div>
  );
};

export default StatusMessage;
