import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { resourceApi } from "./features/resources/resourceApiSlice";
import { deficiencyApi } from "./features/deficiencies/deficiencyApiSlice";
import { inspectionApi } from "./features/inspectionLine/inspectionApiSlice";
import { usersApi } from "./features/users/usersApiSlice";
import profileReducer from "./features/profile/profileSlice";
import { pingApi } from "./features/ping/ping";

export const store = configureStore({
  reducer: {
    [resourceApi.reducerPath]: resourceApi.reducer,
    [deficiencyApi.reducerPath]: deficiencyApi.reducer,
    [inspectionApi.reducerPath]: inspectionApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [pingApi.reducerPath]: pingApi.reducer,
    profile: profileReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      resourceApi.middleware,
      deficiencyApi.middleware,
      inspectionApi.middleware,
      usersApi.middleware,
      pingApi.middleware
    ),
});

setupListeners(store.dispatch);
