import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration.js";
//import { createBrowserRouter, RouterProvider } from "react-router-dom";
//import * as routes from "./routing/nav_routes_builder.js";
import "regenerator-runtime/runtime";
//import { store } from "./redux/store.js";
//import { Provider } from "react-redux";

//import "bootstrap/dist/css/bootstrap.min.css";

// For Saas import Saas.scss
import "./assets/scss/Saas.scss";

import "./index.css";
import App from "./App.jsx";

//const router = createBrowserRouter(routes.build());

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <Provider store={store}>
      <RouterProvider router={router} />
    </Provider> */}
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// TODO: IMPORTANT - Before registering - Update sw code and make sure login flow navigation is excluded from the cache
console.log("index.js - registering SW");

//if (process.env.NODE_ENV === "production") serviceWorkerRegistration.register();
//else serviceWorkerRegistration.unregister();
//const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
//navigator.serviceWorker.register(swUrl);
serviceWorkerRegistration.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
