export const buildEmptyInspectionImageItem = (index) => {
  return {
    imageIndex: index,
    part: null,
    image: null,
    imageUri: null,
    loading: false,
  };
};

export const findInspectionImageItemByIndex = (items, imageIndex) => {
  const partItem = items?.find((p) => p.imageIndex === imageIndex);
  return partItem;
};

export const mapApiInspectionImage = (api, parts) => {
  const empty = buildEmptyInspectionImageItem(api.imageIndex);
  empty.imageUri = api.blobUri;
  empty.part = parts.find((p) => p.id === api.partId);
  return empty;
};

export const buildImageItemsArray = (apiResult, parts) => {
  console.log("buildImageItemsArray", apiResult);
  const items = [];
  for (let index = 0; index < 6; index++) {
    const existing = apiResult?.find((i) => i.imageIndex === index);
    if (existing) items.push(mapApiInspectionImage(existing, parts));
    else items.push(buildEmptyInspectionImageItem(index));
  }
  return items;
};
