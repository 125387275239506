import React from "react";

const SearchTextInput = ({
  label,
  initialValue,
  onValueChanged,
  className,
  disabled,
}) => {
  const forKey = "search";
  return (
    <div className={className}>
      {label ?? (
        <label
          htmlFor={forKey}
          className="form-label fs-5"
        >
          {label}
        </label>
      )}
      <div className="input-group">
        <input
          type="text"
          className="form-control fs-4"
          placeholder="Search..."
          aria-label="Search"
          aria-describedby="search"
          value={initialValue ?? ""}
          disabled={disabled}
          onChange={(e) => onValueChanged(e.target.value)}
        />
        <div className="input-group-append">
          <span className="input-group-text ri-search-line fs-4" id="search" />
        </div>
      </div>
    </div>
  );
};

export default SearchTextInput;
