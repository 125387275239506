import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "../../../services/constants/appConsts";

const baseController = ENDPOINTS.API() + "/resource/";

//! make api call to get resources

// no initial state for this service
// it will return and save to the api for us

export const resourceApi = createApi({
  reducerPath: "resources",
  baseQuery: fetchBaseQuery({ baseUrl: baseController }),
  tagTypes: ["Resources"],
  endpoints: (builder) => ({
    getResources: builder.query({
      query: () => "GetResourceManifest",
      providesTags: ["Resources"],
    }),
    getElevatorContacts: builder.query({
      query: (elevatorCompanyId) => `GetElevatorContacts/${elevatorCompanyId}`,
      providesTags: ["ElevatorContacts"],
    }),
  }),
});

export const { useGetResourcesQuery, useLazyGetElevatorContactsQuery } =
  resourceApi;
