import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

// components
import Tabs from "./components/tabs/_Tabs";

//css
import "./TabLayout.css";
import { Stack } from "react-bootstrap";
import { INTERNAL_ROUTES } from "../../routing/nav_routes";

function TabLayout() {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && location?.pathname === "/") navigate(INTERNAL_ROUTES.HOME, { replace: true });
  }, [isAuthenticated, location, navigate]);

  if (isAuthenticated)
    return (
      <div className="tab-layout">
        <Stack direction="vertical" className="justify-content-between">
          <div style={{ height: "91vh", overflowY: "auto", overflowX: "visible" }}>
            <Outlet />
          </div>
          <Tabs />
        </Stack>
      </div>
    );
}

export default TabLayout;
