import { useInspectionLine } from "./useInspectionLine";
import { useGetDeficienciesHistoryQuery } from "../redux/features/deficiencies/deficiencyApiSlice";
import { DEFS_ERRORS } from "../services/constants/deficiencyMessages";
import { buildFromQuery } from "../services/utils/statusUtils";

export const useDefHistory = () => {
  const {
    inspectionLine,
    isLoading: insLoading,
    isSuccess: insSuccess,
    status: insStatus,
  } = useInspectionLine();

  const defHistoryApi = useGetDeficienciesHistoryQuery(
    {
      deviceId: inspectionLine?.deviceId,
      inspectionId: inspectionLine?.inspectionId,
    },
    {
      skip: !inspectionLine || insLoading || !insSuccess,
    }
  );

  const { data, isSuccess, isLoading, isFetching } = defHistoryApi;
  const status = buildFromQuery(
    defHistoryApi,
    null,
    DEFS_ERRORS.FAILED_TO_LOAD_DEF_HISTORY
  );

  const refetch = () => {
    defHistoryApi.refetch();
  };

  const histories = data?.results;

  console.log('history', data);

  return {
    inspectionLine,
    histories,
    isLoading: insLoading || isLoading || isFetching,
    isSuccess: insSuccess && isSuccess,
    status: !insSuccess ? insStatus : status,
    refetch,
  };
};
