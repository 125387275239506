import React from "react";
import { Button } from "react-bootstrap";

const LargeButton = ({
  title,
  className,
  onClick,
  disabled,
  loading,
  size,
  fontSize,
  variant,
  tooltip,
}) => {
  const fontS = fontSize ?? "fs-3";

  const renderSpinner = () => {
    if (loading)
      return (
        <span className="spinner-border text-white spinner-border-sm"></span>
      );
  };

  const getVariant = () => {
    if (disabled) return "secondary";
    return variant ?? "info";
  };

  return (
    <Button
      variant={getVariant()}
      size={size ?? "lg"}
      title={title}
      className={`d-flex justify-content-center align-items-center gap-2 ${fontS} ${className}`}
      disabled={disabled}
      onClick={onClick}
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      data-bs-title="This top tooltip is themed via CSS variables."
    >
      {renderSpinner()}
      {title}
    </Button>
  );
};

export default LargeButton;
