import React from "react";

import DeviceLine from "./_DeviceLine";
import { Stack } from "react-bootstrap";

const BuildingDevices = ({ building }) => {
  const renderDevices = () => {
    return building?.devices?.map((device, i) => {
      return <DeviceLine device={device} key={i} />;
    });
  };

  return <Stack direction="vertical" gap={1}>{renderDevices()}</Stack>;
};

export default BuildingDevices;
