import React, { useState, useEffect } from "react";

//css
import "./_InspectionImageLine.css";

// components
import { Badge, Button, Stack } from "react-bootstrap";
import ConfirmModal from "../../shared/components/modal/_ConfirmModal";
import Label from "../../shared/components/label/_Label";
import { STATUS_MESSAGES } from "../../../services/constants/inspectionImageConsts";
import IconButton from "../../shared/components/buttons/_IconButton";

const InspectionImageLine = ({
  partItem: imageItem,
  onSelectImage,
  onSelectPart,
  onClearPartItem,
  disabled,
}) => {
  const [fileDataURL, setFileDataURL] = useState(null);
  const [showClearModal, setShowClearModal] = useState(false);

  useEffect(() => {
    let fileReader,
      isCancel = false;

    if (!imageItem) return;

    if (imageItem.imageUri) {
      //if image URI is coming from backend return and dont anything
      if (fileDataURL) {
        setFileDataURL(null);
      }
      return;
    }

    if (imageItem.image) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(imageItem.image);
    }

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [fileDataURL, imageItem, imageItem?.image, imageItem?.imageUri]);

  const select = (file) => {
    onSelectImage(imageItem, file.files[0]);
  };

  const onClearClick = () => {
    setShowClearModal(true);
  };

  const onClearConfirmed = () => {
    setShowClearModal(false);

    onClearPartItem(imageItem);
  };

  const renderImage = () => {
    if (fileDataURL)
      return (
        <img
          className="inspection-line-image"
          src={fileDataURL}
          alt="Inspection line"
        />
      );

    if (imageItem.imageUri)
      return (
        <img
          className="inspection-line-image"
          src={imageItem.imageUri}
          alt="Inspection line"
        />
      );

    return (
      <div className="inspection-line-image d-flex justify-content-center align-items-center">
        <i className="display-6 ri-file-2-line " />
      </div>
    );
  };

  const isLocked = imageItem.imageUri ? true : false;
  const pending =
    (imageItem.part || imageItem.image) && !imageItem.imageUri ? true : false;
  const partIsMissing = !imageItem.part && !imageItem.imageUri ? true : false;
  const pendingMsg = partIsMissing
    ? "Pending, select a part to update"
    : "Pending, select an image to update";

  const renderButtons = () => {
    if (isLocked)
      return (
        <IconButton
          disabled={disabled}
          iconClass={"display-6 ri-delete-bin-2-line"}
          onClick={onClearClick}
        />
      );

    return (
      <Stack gap={2} direction="horizontal">
        <IconButton
          disabled={disabled}
          iconClass={"display-6 ri-settings-4-line"}
          onClick={() => onSelectPart(imageItem)}
        />
        <Button disabled={disabled} variant="link" className="border-0">
          <label className="display-6 ri-camera-line">
            <input
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              onChange={(e) => select(e?.target)}
            />
          </label>
        </Button>
      </Stack>
    );
  };

  return (
    <>
      <ConfirmModal
        show={showClearModal}
        title={"Confirm"}
        text={STATUS_MESSAGES.CONFIRM_CLEARING}
        onConfirm={onClearConfirmed}
        onClose={() => setShowClearModal(false)}
      />
      <Stack
        gap={3}
        direction="horizontal"
        className={`mt-2 align-items-center`}
      >
        {renderImage()}
        <h4 className="m-0 flex-fill">
          {imageItem?.part
            ? `${imageItem.part.item} - ${imageItem.part.description}`
            : "None"}
        </h4>
        {pending ? (
          <Badge bg="warning">
            <Label text={pendingMsg} className={"m-0"} />
          </Badge>
        ) : (
          <></>
        )}
        {renderButtons()}
      </Stack>
    </>
  );
};

export default InspectionImageLine;
