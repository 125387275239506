export const BRAKE_COMPLIANCE_COMPONENTS = {
    MAIN: 'main',
    MANUFACTURER: 'manufacturer',
}

export const BRAKE_COMPLIANCE_CONST = {
    SELECT_MANUFACTURER_DESCRIPTION: 'Select a Machine Manufacturer',
    SELECT_MANUFACTURER_TITLE: 'MACHINE MANUFACTURER',

    UPDATE_SUCCESS: 'Updated successfully!',
    UPDATE_FAILED: 'Failed to update, please try again.'
    
}