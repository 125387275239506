import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EXTERNAL_ROUTES } from "../../routing/nav_routes";
import { Button, Image, Stack } from "react-bootstrap";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import PageSpinner from "../../internal/shared/components/spinner/_PageSpinner";
import { navigateToLogout } from "../../services/api/authApi";
import { VERSION } from "../../services/constants/appConsts";
import { useApiPing } from "../../hooks/useApiPing";

const Forbidden = () => {
  const navigate = useNavigate();
  const { user, isLoading: userLoading } = useGetCurrentUser();
  const { refetch, isLoading, isSuccess, status } = useApiPing();
  const [navigateAfterPing, setNavigateAfterPing] = useState(false);

  useEffect(() => {
    if (!user && !userLoading) {
      navigate(EXTERNAL_ROUTES.LOGIN, { replace: true });
    }
    return () => {};
  }, [user, isLoading, navigate]);

  useEffect(() => {
    if (isSuccess && navigateAfterPing) {
      navigateToLogout();
    }
  }, [navigateAfterPing, isSuccess, navigate]);

  const onLogout = () => {
    refetch();
    setNavigateAfterPing(true);
  };

  const renderButton = () => {
    if (user) {
      return (
        <Stack className="justify-content-center align-items-center">
          <h3>User doesn't have access</h3>
          <h4>Try using a different account or contact dispatch</h4>
          <Button variant="warning" className="fs-3 mt-5" onClick={onLogout}>
            Logout
          </Button>
        </Stack>
      );
    }
  };

  const render = () => {
    if (isLoading) return <PageSpinner show={true} />;

    return (
      <>
        <div className="d-flex justify-content-center">{renderButton()}</div>
      </>
    );
  };

  return (
    <Stack
      style={{ height: "85vh" }}
      className="align-items-center justify-content-center"
      gap={1}
    >
      <Image
        title="VDA logo"
        src="vda-logo.svg"
        style={{ height: "10rem", width: "10rem" }}
      />
      <h2>VDA Mobile</h2>
      <h3>v: {VERSION}</h3>
      {render()}
    </Stack>
  );
};

export default Forbidden;
