export const MOBILE_STATUS = {
  TODO: "ToDo",
  COMPLETE: "Complete",
};

export const MOBILE_STATUS_BUTTON = {
  TODO: "To Do",
  COMPLETE: "Complete",
};

export const CAT_1_PERIODIC_RESULTS_LABELS = {
  0: "N/A",
  1: "Satisfactory",
  2: "Unsatisfactory",
};

export const CAT_1_PERIODIC_RESULTS_INDEXES = {
  NA: 0,
  SATISFACTORY: 1,
  UNSATISFACTORY: 2,
};

export const CAT_1_PERIODIC_STATUS = {
  NA: "N/A",
  SATISFACTORY: "Satisfactory",
  UNSATISFACTORY: "Unsatisfactory",
};

export const CAT_5_STATUS = {
  NA: "N/A",
  SATISFACTORY: "Satisfactory",
};

export const CAT_5_INDEXES = {
  NA: 0,
  SATISFACTORY: 1,
};

export const CAT_TYPES = {
  CAT1: 1,
  CAT1_CAT5: 2,
  CAT5: 3,
  PERIODIC: 7,
};

export const CAT5RESULTS = [CAT_5_STATUS.NA, CAT_5_STATUS.SATISFACTORY];

export const CAT1RESULTS = [
  CAT_1_PERIODIC_STATUS.NA,
  CAT_1_PERIODIC_STATUS.SATISFACTORY,
  CAT_1_PERIODIC_STATUS.UNSATISFACTORY,
];
