import React from "react";
import { Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import IconButton from "../buttons/_IconButton";
import { ICON_CLASSES } from "../../constants/icons";

const Header = ({
  title,
  subTitle,
  onBack,
  actionClassName,
  onAction,
  actionText,
  refresh,
}) => {
  const navigate = useNavigate();

  const onBackClick = () => {
    if (onBack) onBack();
    else navigate(-1);
  };

  const onActionClick = () => {
    if (onAction) onAction();
  };

  const renderRefresh = () => {
    if (!refresh) return;

    return (
      <IconButton
        className={"p-1"}
        iconClass={"ri-refresh-line"}
        size={"md"}
        onClick={refresh}
      />
    );
  };

  const renderAction = () => {
    if (actionClassName) {
      return (
        <IconButton
          title={"Action"}
          iconClass={actionClassName}
          size={"lg"}
          onClick={onActionClick}
          text={actionText}
        />
      );
    }
  };

  return (
    <Stack
      direction="horizontal"
      gap={3}
      className="justify-content-between mb-3"
    >
      <IconButton
        title={"Back"}
        iconClass={ICON_CLASSES.ARROW_LEFT}
        size={"lg"}
        onClick={onBackClick}
      />
      <Stack direction="horizontal" gap={3}>
        <div className="text-end">
          <h2>{title}</h2>
          {subTitle ? <h3>{subTitle}</h3> : <></>}
        </div>
        {renderRefresh()}
        {renderAction()}
      </Stack>
    </Stack>
  );
};

export default Header;
