export const parseQueryError = (api) => {
  if (!api) return buildNoError();
  return parseError(api.isError, api?.data, api?.error);
};

export const parseMutationError = (result) => {
  //console.log("parseMutationError", result);
  if (!result) return buildNoError();
  return parseError(result.isError, result?.data, result?.error);
};

const parseError = (isError, data, error) => {
  const result = data ?? error?.data;
  const responseError = parseResponseError(result, error?.status);

  if (responseError.hasError) return responseError;

  if (error || isError) {
    const statuCode = error?.status;
    const errorMsg = "Request failed!";
    const apiError = buildError(true, errorMsg, false, statuCode);
    //console.log("parseError", isError, error, apiError);
    return apiError;
  }

  return buildNoError();
};

const parseResponseError = (result, statusCode) => {
  if (!result) return buildNoError();

  const error = result?.error;
  const hasError = result?.hasError ?? false;
  const isValidationError = error?.isValidationError ?? false;
  const parsed = buildError(
    hasError,
    error?.errorMessage,
    isValidationError,
    statusCode
  );

  return parsed;
};

const buildNoError = () => buildError(false, null, false, );

const buildError = (hasError, errorMsg, isValidationError, statusCode) => ({
  hasError,
  errorMsg,
  isValidationError,
  statusCode,
});
