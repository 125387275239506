import React from "react";
import { Badge, Stack } from "react-bootstrap";
import StatusMessage from "../status-message/_StatusMessage";
import IconButton from "../buttons/_IconButton";

const PageError = ({ show, status, refresh }) => {
  //if (status) console.log("PageError", status);
  if (!show) return <></>;

  const renderRefresh = () => {
    if (!refresh) return;

    return (
      <IconButton
        className={"p-1"}
        iconClass={"ri-refresh-line"}
        size={"md"}
        onClick={refresh}
      />
    );
  };

  return (
    <Stack
      style={{ height: "85vh" }}
      className="justify-content-center align-items-center"
      direction="vertical"
      gap={2}
    >
      <Stack
        direction="horizontal"
        className="justify-content-center align-items-center"
      >
        <h1>
          <Badge bg="danger">Oops!</Badge> Something went wrong!
        </h1>
        {renderRefresh()}
      </Stack>
      <StatusMessage status={status} />
    </Stack>
  );
};

export default PageError;
