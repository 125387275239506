import React, { useState } from "react";

// components
import { Col, Container, Row, Stack } from "react-bootstrap";
import Header from "../../../shared/components/header/_Header";
import DeficiencyDetailLine from "./_DeficiencyDetailLine";
import Label from "../../../shared/components/label/_LabelGroup";
import LargeButton from "../../../shared/components/buttons/_LargeButton";

//services
import { DEFICIENCY_DETAILS_COMPONENTS } from "./_DeficiencyDetailComponents";
import StatusMessage from "../../../shared/components/status-message/_StatusMessage";
import PageError from "../../../shared/components/error/_PageError";
import PageSpinner from "../../../shared/components/spinner/_PageSpinner";
import { useUpsertDeficiencyMutation } from "../../../../redux/features/deficiencies/deficiencyApiSlice";
import { buildFromMutation } from "../../../../services/utils/statusUtils";
import { useParams } from "react-router-dom";

const DeficiencyDetailMain = ({
  selectedDeficiency,
  isLoading,
  insStatus,
  defGetStatus,
  inspectionLine: line,
  //defUpdateStatus,
  onShowComponent,
  onBack,
  //onSubmitDeficiency,
  disabled,
}) => {
  const { inspectionLineId, deficiencyId } = useParams();
  const [defUpdating, setDefUpdating] = useState(false);
  const [defUpdateStatus, setDefUpdateStatus] = useState(null);
  const [upsertDeficiency] = useUpsertDeficiencyMutation();

  const onSelectPart = () => {
    onShowComponent(DEFICIENCY_DETAILS_COMPONENTS.PART);
  };

  const onSelectRemedy = () => {
    onShowComponent(DEFICIENCY_DETAILS_COMPONENTS.REMEDY);
  };

  const onSelectViolation = () => {
    onShowComponent(DEFICIENCY_DETAILS_COMPONENTS.VIOLATION);
  };

  const onSubmitDeficiency = async () => {
    setDefUpdating(true);

    let payload = {
      deficiencyId: deficiencyId,
      inspectionLineId: inspectionLineId,
      inspectionId: line.inspectionId,
      customerId: line.customerId,
      buildingId: line.buildingId,
      deviceId: line.deviceId,
      partId: selectedDeficiency.part.id,
      isBuildingResponsibility: selectedDeficiency.part.isBuildingResponsibility
        ? 1
        : 0,
      violationId: selectedDeficiency.violation.id,
      remedyId: selectedDeficiency.remedy.id,
    };

    const response = await upsertDeficiency(payload);
    const updateStatus = buildFromMutation(response);
    setDefUpdating(false);

    if (updateStatus.hasError) {
      setDefUpdateStatus(updateStatus);
      return;
    }

    onBack(true);
  };

  const renderMainContent = () => {
    if (defGetStatus?.hasError)
      return <PageError show={true} status={defGetStatus} />;

    if (insStatus?.hasError)
      return <PageError show={true} status={insStatus} />;

    if (isLoading) return <PageSpinner show={true} />;

    return (
      <Stack gap={3} className="mt-2">
        <StatusMessage
          status={defUpdateStatus}
          show={defUpdateStatus?.hasError}
        />

        <DeficiencyDetailLine
          name={"Parts"}
          text={"SELECTED ELEVATOR PART"}
          onSelect={onSelectPart}
        />
        <Label
          text={`Part: ${selectedDeficiency.part?.item}`}
          textClass={"px-2"}
        />
        <Label
          text={`Location: ${selectedDeficiency.part?.description}`}
          textClass={"px-2"}
        />

        <DeficiencyDetailLine
          name={"Violations"}
          text={"SELECTED VIOLATING CONDITION"}
          onSelect={onSelectViolation}
        />
        <Label
          text={`Violation:  ${selectedDeficiency.violation?.item} - ${selectedDeficiency.violation?.description}`}
          textClass={"px-2"}
        />

        <DeficiencyDetailLine
          name={"Remedies"}
          text={"SELECTED REMEDY"}
          onSelect={onSelectRemedy}
        />
        <Label
          text={`Remedy: ${selectedDeficiency.remedy?.item} - ${selectedDeficiency.remedy?.description}`}
          textClass={"px-2"}
        />
        <LargeButton
          loading={defUpdating}
          onClick={onSubmitDeficiency}
          title={selectedDeficiency.isUpdate ? "Update" : "Add"}
          variant={defUpdating ? "secondary" : null}
          disabled={disabled}
        />
      </Stack>
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <Header
            title={"Selected Deficiencies"}
            subTitle={"Click the edit icon to make changes"}
            onBack={onBack}
          />
        </Col>
      </Row>
      <Row>
        <Col>{renderMainContent()}</Col>
      </Row>
    </Container>
  );
};

export default DeficiencyDetailMain;
