import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ErrorModal({ title, error, onClose }) {
  if (!error) return;

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>{title}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{error}</h5>
      </Modal.Body>
      <Modal.Footer>
        <Button size="lg" variant="secondary" onClick={onClose}>
          {"Close"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ErrorModal;
