import React from "react";
import { Badge, Stack } from "react-bootstrap";
import IconButton from "../../shared/components/buttons/_IconButton";
import { ICON_CLASSES } from "../../shared/constants/icons";
import { useNavigate } from "react-router-dom";
import {
  INSPECTION_ROUTES_PARAMS,
  INTERNAL_ROUTES,
} from "../../../routing/nav_routes";
import {
  getMobileStatusLabel,
  getStatusBadgeColor,
} from "../../../services/utils/inspectionType";

const DeviceLine = ({ device }) => {
  const navigate = useNavigate();
  const onClick = () => {
    // set lineId to route and navigate to inspection line
    navigate(
      INTERNAL_ROUTES.INSPECTION.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        device.inspectionLineId
      ),
      { state: { source: "map" } }
    );
  };

  return (
    <Stack
      direction="horizontal"
      gap={0}
      className="bottom-border justify-content-between align-items-center pb-1"
    >
      <Stack direction="vertical" gap={1} className="justify-content-start align-items-start">
        <p className="card-text m-0">Device</p>
        <p className="card-text m-0 fw-bold">{device.deviceName}</p>
      </Stack>
      <Stack direction="vertical" gap={1} className="justify-content-start align-items-start">
        <p className="card-text m-0">Type</p>
        <p className="card-text m-0 fw-bold">
          <Badge
            bg="secondary"
            className="m-0 p-1"
            style={{ fontSize: "inherit" }}
          >
            {device.inspectionType}
          </Badge>
        </p>
      </Stack>
      <Stack direction="vertical" gap={1} className="justify-content-start align-items-start">
        <p className="card-text m-0">Status</p>
        <p className="card-text m-0 fw-bold">
          <Badge
            className={`${getStatusBadgeColor(device.isCompleted, device.mobileStatus)} m-0 p-1`}
            style={{ fontSize: "inherit" }}
          >
            {`${getMobileStatusLabel(device.isCompleted, device.mobileStatus)}`}
          </Badge>
        </p>
      </Stack>

      <IconButton
        title={"Open device"}
        size={"md"}
        iconClass={ICON_CLASSES.ARROW_RIGHT}
        onClick={onClick}
        className={"p-0"}
      />
    </Stack>
  );
};

export default DeviceLine;
