import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from "../../routing/nav_routes";
import { Button, Image, Stack } from "react-bootstrap";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import PageSpinner from "../../internal/shared/components/spinner/_PageSpinner";
import StatusMessage from "../../internal/shared/components/status-message/_StatusMessage";
import { navigateToLogout } from "../../services/api/authApi";
import { VERSION } from "../../services/constants/appConsts";

const LoginCallback = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { user, isLoading, status } = useGetCurrentUser();

  useEffect(() => {
    if (!user) return;

    if (user.hasAccess) {
      navigate(INTERNAL_ROUTES.HOME, { replace: true });
    } else {
      navigate(EXTERNAL_ROUTES.FORBIDDEN, { replace: true });
    }
  }, [user, navigate]);

  const renderUpdateMessage = () => {
    const error = searchParams.get("error");
    //console.log("error", error);
    if (error)
      return (
        <h4 className={`fade text-danger alert alert-danger show m-0`}>
          {error}
        </h4>
      );
  };

  const render = () => {
    if (isLoading) return <PageSpinner show={true} />;

    if (user && !user.hasAccess) {
      return (
        <>
          <h1>Login result</h1>

          {renderUpdateMessage()}
          <StatusMessage status={status} show={status.hasError} />
          <h2>User does not have access to the application</h2>
          <div className="d-flex justify-content-center">
            <Button
              variant="warning"
              className="fs-3 mt-5"
              onClick={navigateToLogout}
            >
              Logout
            </Button>
          </div>
        </>
      );
    }

    return (
      <>
        <h1>Login result</h1>
        {renderUpdateMessage()}
        <StatusMessage status={status} show={status.hasError} />
        <div className="d-flex justify-content-center">
          <Button
            variant="warning"
            className="fs-3 mt-5"
            onClick={() => navigate(EXTERNAL_ROUTES.LOGIN, { replace: true })}
          >
            Go to login
          </Button>
        </div>
      </>
    );
  };

  return (
    <Stack
      style={{ height: "85vh" }}
      className="align-items-center justify-content-center"
      gap={1}
    >
      <Image
        title="VDA logo"
        src="vda-logo.svg"
        style={{ height: "10rem", width: "10rem" }}
      />
      <h2>VDA Mobile</h2>
      <h3>v: {VERSION}</h3>
      {render()}
    </Stack>
  );
};

export default LoginCallback;
