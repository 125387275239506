import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { APIProvider, Map } from "@vis.gl/react-google-maps";

// components
import MarkerWithInfoWindow from "./_MarkerWithInfoWindow";

// css
import "./map.css";

//services
import { DEFAULT_MAP_CENTER } from "../../services/constants/mapConsts";
import HeaderWithDaySelection from "../shared/components/header/_HeaderWithDaySelection";
import { useInspections } from "../../hooks/useInspections";
import Buildings from "./components/_Buildings";

const ScheduleMap = () => {
  const [selectedDayBuildingsMap, setSelectedDayBuildingsMap] = useState([]);
  const [mapCenter, setMapCenter] = useState({
    lat: DEFAULT_MAP_CENTER.LAT,
    lng: DEFAULT_MAP_CENTER.LNG,
    zoom: DEFAULT_MAP_CENTER.ZOOM,
  });
  const [selectedBuilding, setSelectedBuilding] = useState({});
  const mapContainer = useRef(null);
  const { inspections, isSuccess } = useInspections();

  useEffect(() => {
    if (isSuccess && inspections) {
      if (inspections.length === 0) {
        setSelectedDayBuildingsMap([]);
        return;
      }

      const result = Object.groupBy(inspections, (item) => item.buildingId);
      const buildings = [];
      const inspectionGroups = Object.values(result);

      for (let index = 0; index < inspectionGroups.length; index++) {
        const inspectionGroup = inspectionGroups[index];
        const first = inspectionGroup[0];
        const building = {
          buildingId: first.buildingId,
          inspectionId: first.inspectionId,
          borough: first.borough,
          buildingCity: first.buildingCity,
          buildingAddress: first.buildingAddress,
          buildingState: first.buildingState,
          inspectionLines: inspectionGroup,
          latitude: first.latitude,
          longitude: first.longitude,
          devices: [],
        };

        for (let g = 0; g < inspectionGroup.length; g++) {
          const inspectionLine = inspectionGroup[g];

          const device = {
            deviceName: inspectionLine.deviceName,
            inspectionLineId: inspectionLine.inspectionLineId,
            inspectionType: inspectionLine.inspectionType,
            lineCompleted: inspectionLine.lineCompleted,
            mobileStatus: inspectionLine.mobileStatus
          };
          building.devices.push(device);
        }

        buildings.push(building);
      }

      const firstBuilding = buildings[0];

      setSelectedDayBuildingsMap(buildings);
      setMapCenter((prev) => ({
        ...prev,
        lat: firstBuilding.latitude,
        lng: firstBuilding.longitude,
      }));
    }
  }, [inspections, isSuccess]);

  const onMarkerClickAssigned = (item) => {
    setSelectedBuilding(item);
    setMapCenter((prev) => ({
      ...prev,
      lat: item.latitude,
      lng: item.longitude,
    }));
  };

  const onMarkerModalClose = () => {
    setSelectedBuilding(null);
  };

  const onBuildingClick = (building) => {
    onMarkerClickAssigned(building);
    mapContainer.current?.scrollIntoView();
  };

  const isBuildingSelected = (building) => {
    return (
      selectedBuilding && selectedBuilding?.buildingId === building.buildingId
    );
  };

  //Official docs here https://visgl.github.io/react-google-maps/docs
  const handleCameraChange = useCallback(
    ({ detail }) => {
      //console.log("handleCameraChange", detail);
      setMapCenter(() => ({
        zoom: detail.zoom,
        lat: detail.center.lat,
        lng: detail.center.lng,
      }));
    },
    [setMapCenter]
  );

  return (
    <>
      <Container className="container-fluid">
        <Row>
          <HeaderWithDaySelection schedulesCount={inspections?.length} />
        </Row>
        <Row className="map-row px-2">
          <div className="col-xl-8 border mt-1">
            <div className="google-maps" ref={mapContainer}>
              <APIProvider apiKey={"AIzaSyDTnuvPuXN7gcUoO0ebgvBGUosJiQURKhY"}>
                <Map
                  zoom={mapCenter.zoom}
                  onCameraChanged={handleCameraChange}
                  center={{ lat: mapCenter.lat, lng: mapCenter.lng }}
                  mapId="SIERRA_MAP_ID"
                  reuseMaps={true}
                  colorScheme={"LIGHT"}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  {selectedDayBuildingsMap &&
                    selectedDayBuildingsMap.map((item, index) => {
                      return (
                        <MarkerWithInfoWindow
                          key={index}
                          onMarkerClick={() => onMarkerClickAssigned(item)}
                          onCloseInfoWindow={() => onMarkerModalClose(item)}
                          title={`Inspection ${item.inspectionId}`}
                          info={item.borough + "\n" + item.buildingAddress}
                          position={{ lat: item.latitude, lng: item.longitude }}
                          showInfoWindow={isBuildingSelected(item)}
                        />
                      );
                    })}
                </Map>
              </APIProvider>
            </div>
          </div>
          <div className="col-xl-4 buildings mt-1">
            <Buildings
              buildings={selectedDayBuildingsMap}
              isBuildingSelected={isBuildingSelected}
              onBuildingSelected={onBuildingClick}
            />
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ScheduleMap;
