import React from "react";
import { Button, Stack } from "react-bootstrap";
import { VERSION } from "../../services/constants/appConsts";
import { navigateToLogout } from "../../services/api/authApi";
import IconButton from "../shared/components/buttons/_IconButton";

const Profile = () => {
  const reload = () => {
    window.location.reload();
  };

  return (
    <Stack
      direction="vertical"
      className="justify-content-center"
      style={{ height: "90vh" }}
    >
      <h1>Profile</h1>

      <Stack gap={2} className="justify-content-center">
        <h2>SierraLive Mobile</h2>
        <Stack
          direction="horizontal"
          className="justify-content-center align-items-center"
        >
          <h3>v: {VERSION}</h3>
          <IconButton
            className={"p-1"}
            iconClass={"ri-refresh-line"}
            size={"md"}
            onClick={reload}
          />
        </Stack>
        <h4 className="text-info" onClick={reload}>
          Press to refresh the app
        </h4>
        <h3>@Sierra Consulting Group, Inc.</h3>
        <h3>New york, New York</h3>
        <div className="d-flex justify-content-center">
          <Button
            variant="danger"
            size="lg"
            title="title"
            className={`fs-3 px-5`}
            onClick={navigateToLogout}
          >
            {"Logout"}
          </Button>
        </div>
      </Stack>
    </Stack>
  );
};

export default Profile;
