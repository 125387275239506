import React from "react";
import { useNavigate } from "react-router-dom";

// components
import { Col, Container, Row, Stack } from "react-bootstrap";
import Header from "../shared/components/header/_Header";

//services
import Label from "../shared/components/label/_Label";
import moment from "moment";
import { CAT_1_PERIODIC_RESULTS_LABELS } from "../../services/constants/inspConsts";
import { useDefHistory } from "../../hooks/useDefHistory";
import PageSpinner from "../shared/components/spinner/_PageSpinner";
import PageError from "../shared/components/error/_PageError";
import { DEFS_ERRORS } from "../../services/constants/deficiencyMessages";
import { danger } from "../../services/utils/statusUtils";
import LabelArea from "../shared/components/label/_LabelArea";

const InspectionDeficiencyHistory = () => {
  const navigate = useNavigate();
  const { inspectionLine, histories, isLoading, status, refetch } =
    useDefHistory();

  const onBackClick = () => {
    navigate(-1);
  };

  const renderHistories = () => {
    return histories.map((history, i) => {
      const time =
        new Date(history?.inspectionDate).toLocaleDateString() +
        " - " +
        moment(history?.inspectionTime, "HH:mm").format("h:mm A");
      return (
        <Stack key={i} direction="vertical" gap={0} className="card p-2">
          <Container>
            <div>
              <h3 className="text-start">Inspection Details</h3>
            </div>
            <Row>
              <Col>
                <div className="text-start">
                  <Label text={`Insp. Date`} />
                  <Label text={time} />
                </div>
              </Col>
              <Col>
                <div className="text-start">
                  <Label text={`Insp. Id`} />
                  <Label text={history?.inspectionId} />
                </div>
              </Col>
              <Col>
                <div className="text-start">
                  <Label text={`Insp. Type`} />
                  <Label text={inspectionLine?.inspectionType} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-start">
                  <Label text={`Results`} />
                  <Label
                    text={CAT_1_PERIODIC_RESULTS_LABELS[history?.cat1Results]}
                  />
                </div>
              </Col>
              <Col>
                <div className="text-start">
                  <Label text={`Elevator Company`} />
                  <Label text={history.elevatorCompany} />
                </div>
              </Col>
              <Col>
                <div className="text-start">
                  <Label text={`Testing Company`} />
                  <Label text={history.testingCompany} />
                </div>
              </Col>
            </Row>
            <div className="text-start mt-2">
              <Label text={`Comments`} />
              <Label text={history.comments ?? "N/A"} />
            </div>
          </Container>

          {history?.deficiencies?.length > 0 ? (
            <Container className="mt-2">
              <h3>Device Deficiencies</h3>
              {renderDeficiencies(history)}
            </Container>
          ) : (
            <></>
          )}
        </Stack>
      );
    });
  };

  const renderDeficiencies = (history) => {
    const def = history?.deficiencies;
    if (!def) {
      return <div>There are no deficiencies to display.</div>;
    }

    return def.map((d, i) => {
      const remedy = d?.remedy;
      const part = d?.elevatorPart;
      const violation = d?.violation;
      return (
        <Row className="border-2 border-secondary border-bottom">
          <Col>
            <div className="text-start">
              <Label text={`Building Responsibility: `} />
              <Label
                className={"text-info"}
                text={part?.isBuildingResponsibility === true ? "Yes" : "No"}
              />
            </div>
          </Col>
          <Col>
            <div className="text-start">
              <Label text={`Elevator Part: `} />
              <Label className={"text-info"} text={part?.description} />
            </div>
          </Col>
          <Col>
            <div className="text-start">
              <Label text={`Violation:`} />
              <Label className={"text-info"} text={violation?.description} />
            </div>
          </Col>
          <Col>
            <div className="text-start">
              <Label text={`Remedy: `} />
              <Label className={"text-info"} text={remedy?.description} />
            </div>
          </Col>
        </Row>
      );
    });
  };

  const getMainContent = () => {
    if (isLoading) return <PageSpinner show={true} />;

    if (status?.hasError)
      return <PageError show={true} status={status} refresh={refetch} />;

    console.log("getmain", histories);

    if (!histories)
      return (
        <PageError
          status={danger(DEFS_ERRORS.FAILED_TO_LOAD_DEF_HISTORY)}
          show={true}
          refresh={refetch}
        />
      );

    return <Stack gap={0}>{renderHistories()}</Stack>;
  };

  return (
    <Container>
      <Row>
        <Col>
          <Header
            title={"Device"}
            subTitle={inspectionLine?.deviceName}
            onBack={onBackClick}
            refresh={refetch}
          />
        </Col>
      </Row>
      <Row>
        <Col>{getMainContent()}</Col>
      </Row>
    </Container>
  );
};

export default InspectionDeficiencyHistory;
