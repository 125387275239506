import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "../../../services/constants/appConsts";

const baseController = ENDPOINTS.API() + "/users/";

export const usersApi = createApi({
  reducerPath: "users",
  baseQuery: fetchBaseQuery({ baseUrl: baseController }),
  tagTypes: ["Auth", "User"],
  endpoints: (builder) => ({
    getCurrentUser: builder.query({
      query: () => "GetCurrentUser",
      providesTags: ["User"],
    }),
    getUsers: builder.query({
      query: () => "GetUsers",
    }),
    setUserId: builder.mutation({
      query: (body) => ({
        url: "SetUserId",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useGetUsersQuery,
  useSetUserIdMutation,
} = usersApi;
