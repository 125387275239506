import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// components
import DeficiencyDetailMain from "./components/_DeficiencyDetailMain";
import ResourceListPage from "../../shared/components/resource-list/_ResourceListPage";

//services
import { DEFICIENCY_DETAILS_COMPONENTS } from "./components/_DeficiencyDetailComponents";
import { useResources } from "../../../hooks/useResources";
import { useInspectionLine } from "../../../hooks/useInspectionLine";
import { useGetDeficiency } from "../../../hooks/useGetDeficinecy";
import ConfirmModal from "../../shared/components/modal/_ConfirmModal";
import { DEF_DETAIL_MESSAGES } from "./_DeficiencyDetailsConsts";

const InspectionDeficiencyDetails = () => {
  const navigate = useNavigate();
  const { elevatorParts, violations, remedies } = useResources();

  const {
    inspectionLine: line,
    isLoading: insLoading,
    status: insStatus,
  } = useInspectionLine();
  const {
    deficiency,
    isLoading: defIsLoading,
    status: defGetStatus,
  } = useGetDeficiency();

  const [state, setState] = useState({
    componentToShow: DEFICIENCY_DETAILS_COMPONENTS.MAIN,
  });

  const [selectedDeficiency, setSelectedDeficiency] = useState({
    part: {
      id: 0,
      description: "Select Part",
      item: "",
      location: "",
      isBuildingResponsibility: false,
    },
    violation: { id: 0, description: "Select Violation", item: "" },
    remedy: { id: 0, description: "Select Remedy", item: "" },
    defUpdateStatus: null,
    defUpdating: false,
    isUpdate: false,
  });

  const [showConfirmPendingAbandon, setShowConfirmPendingAbandon] =
    useState(false);

  const isLoading = defIsLoading || insLoading;

  const isOriginalModified = () => {
    if (
      deficiency &&
      (selectedDeficiency.part.id !== deficiency.elevatorPart?.elevatorPartId ||
        selectedDeficiency.violation.id !== deficiency.violation?.violationId ||
        selectedDeficiency.remedy.id !== deficiency.remedy?.remedyId)
    ) {
      return true;
    }

    return false;
  };

  const isEmpty = () => {
    if (
      selectedDeficiency.part.id === 0 &&
      selectedDeficiency.violation.id === 0 &&
      selectedDeficiency.remedy.id === 0
    ) {
      return true;
    }

    return false;
  };

  const isPending = () => {
    if (!deficiency && !isEmpty()) {
      return true;
    }

    if (isOriginalModified()) {
      return true;
    }

    return false;
  };

  const disabled = !isPending();

  useEffect(() => {
    if (deficiency) {
      setSelectedDeficiency({
        part: {
          id: deficiency.elevatorPart?.elevatorPartId,
          description: deficiency.elevatorPart?.description,
          item: deficiency.elevatorPart?.item,
          location: deficiency.elevatorPart?.location,
          isBuildingResponsibility:
            deficiency.elevatorPart?.isBuildingResponsibility,
        },
        violation: {
          id: deficiency.violation?.violationId,
          description: deficiency.violation?.description,
          item: deficiency.violation?.item,
        },
        remedy: {
          id: deficiency.remedy?.remedyId,
          description: deficiency.remedy?.description,
          item: deficiency.remedy?.item,
        },
        defUpdateStatus: null,
        defUpdating: false,
        isUpdate: true,
      });
    } else {
      console.log("Deficiency not found - we are going to insert");
    }
  }, [deficiency]);

  const navigateBack = () => {
    // navigate(
    //   INTERNAL_ROUTES.INSPECTION_DEFICIENCIES.replace(
    //     INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
    //     inspectionLineId
    //   )
    // );

    navigate(-1);
  };

  const onBackClick = (force) => {
    //! BACK TO LIST OF DEFICIENCIES FOR INSPECTION LINE
    // this is where we check if we should insert or update
    // on update only update if values are different - don't update if values are the same

    if (!force && isPending()) {
      setShowConfirmPendingAbandon(true);
      return;
    }

    navigateBack();
  };

  const onSelectPart = (part) => {
    // user selected a part - save to local state
    setSelectedDeficiency((prev) => ({ ...prev, part }));

    // show the defficiency details component
    backToMainComponent();
  };

  const onSelectRemedy = (remedy) => {
    // user selected a remedy - save to local state
    setSelectedDeficiency((prev) => ({ ...prev, remedy }));

    // show the defficiency details component
    backToMainComponent();
  };

  const onSelectViolation = (violation) => {
    // user selected a violation - save to local state
    setSelectedDeficiency((prev) => ({ ...prev, violation }));

    // show the defficiency details component
    backToMainComponent();
  };

  const backToMainComponent = () => {
    setState((prev) => ({
      ...prev,
      componentToShow: DEFICIENCY_DETAILS_COMPONENTS.MAIN,
    }));
  };

  const onShowComponent = (comp) => {
    // should we show lists of resources? or the deficiency details component
    setState((prev) => ({ ...prev, componentToShow: comp }));
  };

  const render = () => {
    //console.log('render', state.componentToShow, elevatorParts);
    switch (state.componentToShow) {
      case DEFICIENCY_DETAILS_COMPONENTS.PART:
        return (
          <ResourceListPage
            title={"Elevator Part"}
            resources={elevatorParts}
            onSelect={onSelectPart}
            onBack={backToMainComponent}
          />
        );
      case DEFICIENCY_DETAILS_COMPONENTS.VIOLATION:
        return (
          <ResourceListPage
            title={"Violating Condition"}
            resources={violations}
            onSelect={onSelectViolation}
            onBack={backToMainComponent}
          />
        );
      case DEFICIENCY_DETAILS_COMPONENTS.REMEDY:
        return (
          <ResourceListPage
            title={"Remedy"}
            resources={remedies}
            onSelect={onSelectRemedy}
            onBack={backToMainComponent}
          />
        );
      default:
        return (
          <>
            <ConfirmModal
              title={"Confirm"}
              text={DEF_DETAIL_MESSAGES.PENDING_DISCLAIMER}
              show={showConfirmPendingAbandon}
              onClose={() => setShowConfirmPendingAbandon(false)}
              onConfirm={() => navigateBack()}
            />
            <DeficiencyDetailMain
              isLoading={isLoading}
              defGetStatus={defGetStatus}
              //defUpdateStatus={selectedDeficiency.defUpdateStatus}
              insStatus={insStatus}
              selectedDeficiency={selectedDeficiency}
              inspectionLine={line}
              onShowComponent={onShowComponent}
              onBack={onBackClick}
              //onSubmitDeficiency={onSubmitDeficiency}
              disabled={disabled}
            />
          </>
        );
    }
  };

  return <div>{render()}</div>;
};

export default InspectionDeficiencyDetails;
