import React from "react";
import { Stack } from "react-bootstrap";

const LabelGroup = ({
  text,
  textClass,
  description,
  descriptionClass,
  subText,
  subTextClass,
  containerClass,
}) => {
  return (
    <Stack className={containerClass}>
      {description ? (
        <h5 className={`fw-light ${descriptionClass}`}>{description}</h5>
      ) : (
        <></>
      )}
      <h4 className={`fw-bold ${textClass}`}>{text}</h4>
      {subText ? (
        <h6 className={`fw-bold ${subTextClass}`}>{subText}</h6>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default LabelGroup;
