import { useParams } from "react-router-dom";
import { useDeficiencies } from "./useDeficiencies";
import { useEffect, useState } from "react";

export const useGetDeficiency = () => {
  const { deficiencyId } = useParams();
  const [deficiency, setDeficiency] = useState(null);

  const {
    deficiencies,
    isLoading: defIsLoading,
    isSuccess: defIsSuccess,
    status: defStatus,
  } = useDeficiencies();

  useEffect(() => {
    const failedToLoadDefs = defStatus?.hasError;
    if (!deficiencyId || failedToLoadDefs) {
      setDeficiency(null);
      return;
    }

    if (defIsLoading || !deficiencies) {
      return;
    }

    const deficiency = deficiencies.find(
      (c) =>  `${c.deficiencyId}` === `${deficiencyId}`
    );

    setDeficiency(deficiency);
  }, [deficiencies, deficiencyId, defIsLoading, defStatus?.hasError]);

  return {
    deficiency,
    isLoading: defIsLoading,
    isSuccess: defIsSuccess,
    status: defStatus,
  };
};
