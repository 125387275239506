import React from "react";
import { Stack } from "react-bootstrap";
import IconButton from "../../../shared/components/buttons/_IconButton";
import Label from "../../../shared/components/label/_Label";

const DeficiencyDetailLine = ({ name, text, onSelect }) => {
  return (
    <Stack direction="horizontal" className="form-control-light px-2">
      <Label text={text} className={"flex-fill"} />
      <IconButton
        title={`Open ${name}`}
        iconClass={"ri-edit-box-line"}
        size={"md"}
        onClick={onSelect}
      />
    </Stack>
  );
};

export default DeficiencyDetailLine;
