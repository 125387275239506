import React from "react";
import BuildingDevices from "./_BuildingDevices";
import { Button, Stack } from "react-bootstrap";

const Buildings = ({ buildings, isBuildingSelected, onBuildingSelected }) => {
  const onBuildingClick = (building) => {
    onBuildingSelected(building);
  };

  const renderBuildings = () => {
    if (buildings.length === 0)
      return (
        <div className={`card m-0 border border-2`}>
          <div className="card-body">
            <h5 className="card-title card-link">Missing inspections</h5>
            <p className="card-text">No inspections were loaded for the day.</p>
          </div>
        </div>
      );

    return buildings.map((building, i) => {
      const selectedBorder = isBuildingSelected(building)
        ? "border-primary"
        : "";
      return (
        <div
          key={i}
          className={`card p-1 m-0 border border-2 ${selectedBorder}`}
          onClick={() => onBuildingClick(building)}
        >
          <div className="card-body">
            <Stack
              direction="vertical"
              className="justify-content-between"
              gap={2}
            >
              <div className="border-bottom border-primary">
                <h5 className="card-title card-link text-start">{`Inspection ${building.inspectionId}`}</h5>
                <p className="card-text text-start">{`${building.buildingAddress}, ${building.buildingCity}, ${building.buildingState}, ${building.borough}`}</p>
              </div>

              <BuildingDevices building={building} />
              <Button variant="link" className="">Select to center on map.</Button>
            </Stack>
          </div>
        </div>
      );
    });
  };

  return (
    <Stack direction="vertical" gap={3} className="justify-content-top">
      {renderBuildings()}
    </Stack>
  );
};

export default Buildings;
