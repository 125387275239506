import React from "react";
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";

const MarkerWithInfoWindow = ({
  title,
  info,
  position,
  onMarkerClick,
  onCloseInfoWindow,
  showInfoWindow,
}) => {
  // `markerRef` and `marker` are needed to establish the connection between
  // the marker and infowindow (if you're using the Marker component, you
  // can use the `useMarkerRef` hook instead).
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <>
      {/* As of February 21st, 2024, google.maps.Marker is deprecated. Please use google.maps.marker.AdvancedMarkerElement instead. */}
      <AdvancedMarker
        ref={markerRef}
        position={position}
        onClick={onMarkerClick}
      />

      {showInfoWindow && (
        <InfoWindow
          headerDisabled={true}
          anchor={marker}
          onClose={onCloseInfoWindow}
        >
          {/* Some arbitrary html to be rendered into the InfoWindow. */}
          <div className="align-items-center" style={{ height: "48px" }}>
            <div className="d-flex justify-content-center align-items-center mb-2">
              <h5 className="m-0 flex-grow-1 text-start">{title}</h5>
              <button
                onClick={onCloseInfoWindow}
                style={{ background: "transparent" }}
                className="border-0"
              >
                <i className="fs-4 p-0 m-0 ri-close-line"></i>
              </button>
            </div>
            <h6 className="m-0">{info}</h6>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default MarkerWithInfoWindow;
