import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row, Stack } from "react-bootstrap";
import moment from "moment/moment";

// components
import Header from "../shared/components/header/_Header";
import InspectionDetails from "./components/_InspectionDetails";
import LargeButton from "../shared/components/buttons/_LargeButton";
import Cat5Results from "./components/_Cat5Results";
import Cat1Results from "./components/_Cat1Results";
import ErrorModal from "../shared/components/modal/_ErrorModal";
import CompletedStatus from "../shared/components/status-message/_CompletedStatus";
import Label from "../shared/components/label/_Label";
import StatusBadge from "../shared/components/badge/_StatusBadge";
import InspectionResources from "./components/_InspectionResources";
import StatusMessage from "../shared/components/status-message/_StatusMessage";
import PageSpinner from "../shared/components/spinner/_PageSpinner";
import PageError from "../shared/components/error/_PageError";

//hooks
import { useInspectionLine } from "../../hooks/useInspectionLine";
import { useDeficiencies } from "../../hooks/useDeficiencies";
import { useUpdateMobileStatus } from "../../hooks/useUpdateMobileStatus";

// services
import {
  INSPECTION_ROUTES_PARAMS,
  INTERNAL_ROUTES,
} from "../../routing/nav_routes";
import {
  getCompleteButtonLabel,
  getCompleteButtonVariant,
} from "../../services/utils/inspectionType";

import { MOBILE_STATUS } from "../../services/constants/inspConsts";
import { INSPECTION_ERRORS } from "../../services/constants/inspectionMessages";
import { danger } from "../../services/utils/statusUtils";
import { validateLine } from "../../services/validation/inspectionValidation";
import { INSPECTION_LABELS } from "./_InspectionConsts";

const Inspection = () => {
  const navigate = useNavigate();
  const { inspectionLineId } = useParams();
  const {
    inspectionLine,
    isLoading: insLoading,
    status: insStatus,
    lockedStatus,
  } = useInspectionLine();
  const {
    deficiencies,
    isLoading: defLoading,
    isSuccess: defIsSuccess,
    status: defStatus,
    forceRefetch
  } = useDeficiencies();
  const [status, setStatus] = useState({});
  const [completeValidation, setCompleteValidation] = useState(null);
  const mobileStatusUpdate = useUpdateMobileStatus();
  const mobileStatus = inspectionLine?.mobileStatus;
  const loading = insLoading || defLoading;
  const isError = defStatus.hasError || insStatus.hasError;
  const errorStatus = defStatus.hasError ? defStatus : insStatus;
  const nbOfDeficiencies = deficiencies?.length;

  const time =
    new Date(inspectionLine?.inspectionDate).toLocaleDateString() +
    " - " +
    moment(inspectionLine?.inspectionTime, "HH:mm").format("h:mm A");

  const onBackClick = () => {
    navigate(-1);
  };

  const onInspectionDetailsClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION_DETAILS.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onCompleteClick = async () => {
    if (lockedStatus.isLineCompleted) return;

    if (inspectionLine.mobileStatus === MOBILE_STATUS.TODO) {
      const error = validateLine(inspectionLine, nbOfDeficiencies);
      if (error) {
        setCompleteValidation(error);
        return;
      }
    }

    const newMobileStatus =
      inspectionLine.mobileStatus === MOBILE_STATUS.COMPLETE
        ? MOBILE_STATUS.TODO
        : MOBILE_STATUS.COMPLETE;

    const updateStatus = await mobileStatusUpdate.update(
      inspectionLine.inspectionLineId,
      newMobileStatus
    );
    setStatus(updateStatus);
  };

  const renderMainContent = () => {
    if (loading) return <PageSpinner show={true} />;
    if (isError) {
      //console.log(insStatus, defStatus);
      return <PageError show={true} status={errorStatus} />;
    }
    if (!inspectionLine)
      return (
        <PageError
          status={danger(INSPECTION_ERRORS.FAILED_TO_LOAD_LINE)}
          show={true}
        />
      );

    return (
      <>
        <ErrorModal
          title={INSPECTION_LABELS.ERROR_MODAL_CANT_COMPLETE}
          error={completeValidation}
          onClose={() => setCompleteValidation(null)}
        />
        <Stack
          direction="horizontal"
          className="align-items-center justify-content-between mb-2"
        >
          <div className="text-center">
            <Label text={INSPECTION_LABELS.INSPECTION_DEVICE} />
            <Label text={inspectionLine?.deviceName} />
          </div>
          <div className="text-center">
            <Label text={INSPECTION_LABELS.INSPECTION_STATUS} />
            <StatusBadge
              isCompleted={lockedStatus.isLineCompleted}
              mobileStatus={mobileStatus}
            />
          </div>
          <div className="text-center">
            <Label text={INSPECTION_LABELS.INSPECTION_ID} />
            <Label text={inspectionLine?.inspectionId} />
          </div>
          <div className="text-center">
            <Label text={INSPECTION_LABELS.INSPECTION_DATE} />
            <Label text={time} />
          </div>
        </Stack>

        <CompletedStatus lockedStatus={lockedStatus} />
        <StatusMessage status={status} />

        <p className="top-border"></p>

        <InspectionDetails
          onInspectionDetailsClick={onInspectionDetailsClick}
        />

        <Stack
          direction="vertical"
          gap={3}
          className="mt-2 mb-3 justify-content-center"
        >
          <Cat5Results
            disabled={!defIsSuccess || lockedStatus.isLocked}
            nbOfDeficiencies={nbOfDeficiencies}
            inspectionLine={inspectionLine}
            onUpdateStatus={setStatus}
            refetchDefs={forceRefetch}
          />

          <Cat1Results
            disabled={!defIsSuccess || lockedStatus.isLocked}
            nbOfDeficiencies={nbOfDeficiencies}
            inspectionLine={inspectionLine}
            onUpdateStatus={setStatus}
            refetchDefs={forceRefetch}
          />

          <p className="top-border"></p>

          <InspectionResources
            isLocked={lockedStatus.isLocked}
            nbOfDeficiencies={nbOfDeficiencies}
            inspectionLine={inspectionLine}
          />
          <p className="top-border"></p>
          <LargeButton
            title={
              lockedStatus.isLineCompleted
                ? INSPECTION_LABELS.COMPLETE_BTN_LOCKED
                : getCompleteButtonLabel(mobileStatus)
            }
            onClick={onCompleteClick}
            disabled={lockedStatus.isLineCompleted}
            variant={getCompleteButtonVariant(
              lockedStatus.isLineCompleted,
              mobileStatus
            )}
          />
        </Stack>
      </>
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <Header title={INSPECTION_LABELS.HEADER_TITLE} onBack={onBackClick} />
        </Col>
      </Row>
      <Row>
        <Col>{renderMainContent()}</Col>
      </Row>
    </Container>
  );
};

export default Inspection;
