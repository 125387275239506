import React from "react";
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from "./nav_routes";

import PublicApp from "../external/layout/ExternalLayout";
import Login from "../external/login/login";
import LoginCallback from "../external/login/login-callback";
import ErrorPage from "../external/error/ErrorPage";
import NotFound from "../external/error/NotFound";

import InternalLayout from "../internal/layout/InternalLayout";
import TabLayout from "../internal/layout/TabLayout";
import Inspection from "../internal/inspection/inspection";
import Scheduler from "../internal/scheduler/scheduler";
import Home from "../internal/dashboard/home-view";
import ScheduleMap from "../internal/map/map";
import InspectionDetails from "../internal/inspection/details/inspection-details";
import Profile from "../internal/profile/profile";
import InspectionDeficiencies from "../internal/deficiencies/inspection-deficiencies";
import InspectionDeficiencyDetails from "../internal/deficiencies/details/deficiency-details";
import BrakeCompliance from "../internal/brake/brake-compliance";
import Inspector from "../internal/inspector/inspector";
import Comments from "../internal/comments/comments";
import InspectionImages from "../internal/inspection-images/inspection-images";
import InspectionDeficiencyHistory from "../internal/deficiency-history/deficiency-history";
import UserSwitch from "../internal/user-switch/user-switch";
import LogoutCallback from "../external/login/logout-callback";
import Forbidden from "../external/login/forbidden";

export function build() {
  return [
    {
      element: <PublicApp />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: EXTERNAL_ROUTES.LOGIN,
          element: <Login />,
        },
        {
          path: EXTERNAL_ROUTES.LOGIN_CALLBACK,
          element: <LoginCallback />,
        },
        {
          path: EXTERNAL_ROUTES.LOGOUT_CALLBACK,
          element: <LogoutCallback />,
        },
        {
          path: EXTERNAL_ROUTES.FORBIDDEN,
          element: <Forbidden />,
        },
      ],
    },
    {
      path: INTERNAL_ROUTES.ROOT,
      element: <TabLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: INTERNAL_ROUTES.HOME,
          element: <Home />,
          index: true,
        },
        {
          path: INTERNAL_ROUTES.SCHEDULER,
          element: <Scheduler />,
        },

        {
          path: INTERNAL_ROUTES.MAP,
          element: <ScheduleMap />,
        },
        {
          path: INTERNAL_ROUTES.PROFILE,
          element: <Profile />,
        },
      ],
    },
    {
      path: INTERNAL_ROUTES.ROOT,
      element: <InternalLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: INTERNAL_ROUTES.INSPECTION,
          element: <Inspection />,
        },
        {
          path: INTERNAL_ROUTES.INSPECTION_DETAILS,
          element: <InspectionDetails />,
        },
        {
          path: INTERNAL_ROUTES.INSPECTION_DEFICIENCIES,
          element: <InspectionDeficiencies />,
        },
        {
          path: INTERNAL_ROUTES.INSPECTION_DEFICIENCIES_DETAILS,
          element: <InspectionDeficiencyDetails />,
        },
        {
          path: INTERNAL_ROUTES.INSPECTION_BRAKE_COMPLIANCE,
          element: <BrakeCompliance />,
        },
        {
          path: INTERNAL_ROUTES.INSPECTION_INSPECTOR,
          element: <Inspector />,
        },
        {
          path: INTERNAL_ROUTES.INSPECTION_COMMENTS,
          element: <Comments />,
        },
        {
          path: INTERNAL_ROUTES.INSPECTION_PHOTOS,
          element: <InspectionImages />,
        },
        {
          path: INTERNAL_ROUTES.INSPECTION_DEFICIENCY_HISTORY,
          element: <InspectionDeficiencyHistory />,
        },
        {
          path: INTERNAL_ROUTES.SWITCH_INSPECTOR,
          element: <UserSwitch />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
      errorElement: <ErrorPage />,
    },
  ];
}
