import React from "react";
import { Button, Stack } from "react-bootstrap";
import Label from "../label/_Label";

const IconButton = ({
  title,
  iconClass,
  size,
  onClick,
  className,
  text,
  disabled,
  variant,
}) => {
  const getSizeClassName = () => {
    switch (size) {
      case "sm":
        return "display-7";
      case "md":
        return "display-6";
      default:
        return "display-3";
    }
  };

  const onButtonClick = () => {
    if (disabled) return;
    if (onClick) onClick();
  };

  return (
    <Button
      className={`m-0 ${className ?? ""}`}
      variant={variant ?? "link"}
      onClick={onButtonClick}
      title={title}
      disabled={disabled}
    >
      <Stack direction="horizontal" gap={text ? 2 : 0}>
        <i className={`${iconClass} ${getSizeClassName()}`}></i>
        <Label text={text} />
      </Stack>
    </Button>
  );
};

export default IconButton;
