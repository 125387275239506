import { buildFromMutation } from "../services/utils/statusUtils";
import { useInsertInspectorMutation } from "../redux/features/inspectionLine/inspectionApiSlice";

export const useAddInspector = () => {
  const [insertInspector] = useInsertInspectorMutation();

  const add = async (
    inspectionLineId,
    companyId,
    firstName,
    lastName,
    license
  ) => {
    const payload = {
      companyId,
      inspectionLineId,
      firstName,
      lastName,    
      license,
    };

    const response = await insertInspector(payload);
    const errorMessage = "Failed to update inspector!";
    const successMessage = "Updated inspector!";
    return buildFromMutation(response, successMessage, errorMessage);
  };

  return {
    add,
  };
};
