import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "../../../services/constants/appConsts";

const baseController = ENDPOINTS.API() + "/healthcheck/";

export const pingApi = createApi({
  reducerPath: "ping",
  baseQuery: fetchBaseQuery({ baseUrl: baseController }),
  tagTypes: ["Health"],
  endpoints: (builder) => ({
    getPing: builder.query({
      query: () => "Ping",
      forceRefetch: () => true,
    }),
  }),
});

export const { useGetPingQuery } = pingApi;
