import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from "../../routing/nav_routes";
import { Button, Image, Stack } from "react-bootstrap";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import PageSpinner from "../../internal/shared/components/spinner/_PageSpinner";
import { navigateToLogout } from "../../services/api/authApi";
import { VERSION } from "../../services/constants/appConsts";

const LogoutCallback = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { user, isLoading, status } = useGetCurrentUser();

  const onLogin = () => {
    navigate(EXTERNAL_ROUTES.LOGIN, { replace: true });
  };

  const renderUpdateMessage = () => {
    const error = searchParams.get("error");
    //console.log("LogoutCallback - error", error);
    if (error) {
      return (
        <>
          <h3>Logout failed</h3>
          <h4 className={`fade text-danger alert alert-danger show m-0`}>
            {error}
          </h4>
        </>
      );
    }
  };

  const renderButton = () => {
    //console.log("renderButton", user, status);
    if (user) {
      return (
        <Stack className="justify-content-center align-items-center">
          <h4>User is still logged in</h4>
          <h5>Try logging out again</h5>
          <h5>If error persist contact dispatch</h5>
          <Button
            variant="warning"
            className="fs-3 mt-5"
            onClick={navigateToLogout}
          >
            Logout
          </Button>
        </Stack>
      );
    }

    return (
      <Stack className="justify-content-center align-items-center">
        <h3>User logged out!</h3>

        <Button variant="warning" className="fs-3 mt-5" onClick={onLogin}>
          Go to Log-In
        </Button>
      </Stack>
    );
  };

  const render = () => {
    if (isLoading) return <PageSpinner show={true} />;

    return (
      <>
        {renderUpdateMessage()}
        {/* <StatusMessage status={status} show={status.hasError} /> */}
        <div className="d-flex justify-content-center">{renderButton()}</div>
      </>
    );
  };

  return (
    <Stack
      style={{ height: "85vh" }}
      className="align-items-center justify-content-center"
      gap={1}
    >
      <Image
        title="VDA logo"
        src="vda-logo.svg"
        style={{ height: "10rem", width: "10rem" }}
      />
      <h2>VDA Mobile</h2>
      <h3>v: {VERSION}</h3>
      {render()}
    </Stack>
  );
};

export default LogoutCallback;
