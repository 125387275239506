export const USERS_API_MESSAGES = {
  FAILED_TO_LOAD_CURRENT_USER:
    "Failed to load user information, please try again.",
  FAILED_TO_LOAD_USERS: "Failed to load users, please try again.",  
  FAILED_TO_UPDATE_USER_ID: "Failed to switch!",
  SUCCESSFULLY_UPDATED_USER_ID: "Switched to use new user!",
};

export const USERS_LABELS = {
  SWITCH_TITLE: "Acting as",
  SWITCH_SUB_TITLE: "Select desired inspector",
  SELECT_ME: "Revert to ME"
};
